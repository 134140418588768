import Vue from "vue";
import store from "@/store/index";
import router from "@/router";
import i18n from "@/libs/i18n";
import { setTimeout } from "core-js";
import { async } from "vue-phone-number-input/dist/vue-phone-number-input.common";
export default async function middleware({ to, next }) {
  var windowWidth = window.innerWidth;
  const loginData = Vue.$cookies.get("customer-talka-login-data", {
    parseJSON: true,
  });
  if (loginData) {
    if (to.name) {
      return next();
    }
    return next({
      name: "home",
    });
  } else {
    if (windowWidth < 960) {
      return next({ name: "login-required" });
    } else {
      store.commit("failMessage", "login_message");
      return next({ name: "home" });
    }
  }
  // return next();
}
