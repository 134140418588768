<template>
  <div class="mobile-bottom-bar">
    <a class="mobile-bottom-bar-route" @click="goToRoute('home')">
      <!-- <mobile-home-icon /> -->
      <LottieAnimation
        ref="homeAnim"
        :animationData="require('@/assets/images/icons/home.json')"
        :loop="false"
        :autoPlay="false"
        :speed="1"
        :height="38"
        :width="38"
      />
      <p class="m-0">{{ $t("nav.home") }}</p>
    </a>
    <a
      class="mobile-bottom-bar-route"
      :to="{ name: 'account-orders' }"
      @click="goToRoute('account-orders')"
    >
      <!-- <mobile-orders-icon /> -->
      <LottieAnimation
        ref="ordersAnim"
        :animationData="require('@/assets/images/icons/orders.json')"
        :loop="false"
        :autoPlay="false"
        :speed="1"
        :height="38"
        :width="38"
      />
      <p class="m-0">{{ $t("nav.orders") }}</p>
    </a>
    <a class="mobile-bottom-bar-route" @click="goToRoute('account-cart')">
      <label
        class="mobile-bottom-bar-label-cart"
        v-if="isShow && cartLength > 0"
        >{{ cartLength }}</label
      >
      <!-- <mobile-cart-icon /> -->
      <LottieAnimation
        ref="cartAnim"
        :animationData="require('@/assets/images/icons/cart.json')"
        :loop="false"
        :autoPlay="false"
        :speed="1"
        :height="38"
        :width="38"
      />
      <p class="m-0">{{ $t("nav.cart") }}</p>
    </a>
    <a class="mobile-bottom-bar-route" @click="goToRoute('account-favorite')">
      <!-- <mobile-favorite-icon /> -->
      <LottieAnimation
        ref="favoritesAnim"
        :animationData="require('@/assets/images/icons/favorites.json')"
        :loop="false"
        :autoPlay="false"
        :speed="1"
        :height="38"
        :width="38"
      />
      <p class="m-0">{{ $t("nav.favorite") }}</p>
    </a>
    <a class="mobile-bottom-bar-route" @click="goToRoute('account-index')">
      <!-- <mobile-account-icon /> -->
      <LottieAnimation
        ref="profileAnim"
        :animationData="require('@/assets/images/icons/profile.json')"
        :loop="false"
        :autoPlay="false"
        :speed="1"
        :height="38"
        :width="38"
      />
      <p class="m-0">{{ $t("nav.account") }}</p>
    </a>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import lottie from "vue-lottie";
import LottieAnimation from "lottie-web-vue";
export default {
  name: "mobile-bottom-bar",
  components: {
    // lottie,
    LottieAnimation,
  },
  data() {
    return {
      cartLength: 0,
      favoriteLength: 0,
      isShow: true,
    };
  },
  destroyed() {},
  computed: {
    ...mapGetters({}),
  },
  methods: {
    handleHomeAnimation(anim) {
      anim.stop();
    },
    handleOrdersAnimation(anim) {
      anim.stop();
    },
    handleCartAnimation(anim) {
      anim.stop();
    },
    handleFavoritesAnimation(anim) {
      anim.stop();
    },
    handleProfileAnimation(anim) {
      anim.stop();
    },
    stop() {
      this.anim.stop();
    },

    play() {
      this.anim.play();
    },
    goToRoute(routeName) {
      if (routeName != this.$route.name) {
        this.$router.push({
          name: routeName,
        });
        if (routeName == "home") {
          this.$refs.homeAnim.play();
          this.$refs.ordersAnim.stop();
          this.$refs.cartAnim.stop();
          this.$refs.favoritesAnim.stop();
          this.$refs.profileAnim.stop();
          this.isShow = true;
        } else if (routeName == "account-orders") {
          this.$refs.homeAnim.stop();
          this.$refs.ordersAnim.play();
          this.$refs.cartAnim.stop();
          this.$refs.favoritesAnim.stop();
          this.$refs.profileAnim.stop();
          this.isShow = true;
        } else if (routeName == "account-cart") {
          this.$refs.homeAnim.stop();
          this.$refs.ordersAnim.stop();
          this.$refs.cartAnim.play();
          this.$refs.favoritesAnim.stop();
          this.$refs.profileAnim.stop();
          this.isShow = false;
        } else if (routeName == "account-favorite") {
          this.$refs.homeAnim.stop();
          this.$refs.ordersAnim.stop();
          this.$refs.cartAnim.stop();
          this.$refs.favoritesAnim.play();
          this.$refs.profileAnim.stop();
          this.isShow = true;
        } else if (routeName == "account-index") {
          this.$refs.homeAnim.stop();
          this.$refs.ordersAnim.stop();
          this.$refs.cartAnim.stop();
          this.$refs.favoritesAnim.stop();
          this.$refs.profileAnim.play();
          this.isShow = true;
        }
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.name == "home") {
        this.$refs.homeAnim.play();
        this.$refs.ordersAnim.stop();
        this.$refs.cartAnim.stop();
        this.$refs.favoritesAnim.stop();
        this.$refs.profileAnim.stop();
      } else if (this.$route.name == "account-orders") {
        this.$refs.homeAnim.stop();
        this.$refs.ordersAnim.play();
        this.$refs.cartAnim.stop();
        this.$refs.favoritesAnim.stop();
        this.$refs.profileAnim.stop();
      } else if (this.$route.name == "account-cart") {
        this.$refs.homeAnim.stop();
        this.$refs.ordersAnim.stop();
        this.$refs.cartAnim.play();
        this.$refs.favoritesAnim.stop();
        this.$refs.profileAnim.stop();
      } else if (this.$route.name == "account-favorite") {
        this.$refs.homeAnim.stop();
        this.$refs.ordersAnim.stop();
        this.$refs.cartAnim.stop();
        this.$refs.favoritesAnim.play();
        this.$refs.profileAnim.stop();
      } else if (this.$route.name == "account-index") {
        this.$refs.homeAnim.stop();
        this.$refs.ordersAnim.stop();
        this.$refs.cartAnim.stop();
        this.$refs.favoritesAnim.stop();
        this.$refs.profileAnim.play();
      }
    }, 1000);
    this.cartLength = localStorage.talka_cart
      ? JSON.parse(localStorage.talka_cart).length
      : [];
    this.favoriteLength = localStorage.talka_favorite
      ? JSON.parse(localStorage.talka_favorite).length
      : [];
    window.addEventListener("talka-cart-localstorage-changed", (event) => {
      this.cartLength = localStorage.talka_cart
        ? JSON.parse(localStorage.talka_cart).length
        : [];
      this.favoriteLength = localStorage.talka_favorite
        ? JSON.parse(localStorage.talka_favorite).length
        : [];
    });
  },
};
</script>
