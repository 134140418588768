// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";

export default {
  async loadUserInfo({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get("web/user/info", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setUserInfo", response.data.data);
          commit("setIsLoggedIn", true);
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async deleteAccount({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("delete-account", data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          commit("successMessage", "the_account_has_been_deleted_successfully");
          this._vm.$cookies.remove("customer-talka-login-data");
          this._vm.$cookies.remove(
            "customer-talka-login-data",
            null,
            "localhost",
            // 'web.talqa.wameedprojects.com',
            true

            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get("customer-talka-login-data", {
            parseJSON: true,
          });
          if (!loginData) {
            this._vm.$http.defaults.headers.common.Authorization = undefined;
            // commit("setLoggedOutuser");
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadNotifications({
    commit
  }, data) {
    // commit("loadingStart", null);
    await this._vm.$http
      .get(`web/user/notifications?page=${data.page}&per_page=${data.per_page}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("setNotifications", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async readNotifications({
    commit
  }, data) {
    // commit("loadingStart", null);
    await this._vm.$http
      .post("web/user/notifications/read", data)
      .then((response) => {
        if (response.status === 200) {
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },

  async loadAddressesTypes({
    commit
  }, data) {
    // commit("loadingStart", null);
    await this._vm.$http
      .get("web/user/addresses/types", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setAddressesTypes", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },

  async loadUserAddresses({
    commit
  }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get("web/user/addresses", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setUserAddresses", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },

  async addNewAddress({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("web/user/addresses/store", data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          return response;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async deleteAddress({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`web/user/addresses/delete/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadUserOrders({
    commit
  }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/user/orders?filters=${data.filters}&status=${data.status}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("setUserOrders", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },

  async loadUserOrderDetails({
    commit
  }, data) {
    // commit("loadingStart", null);
    await this._vm.$http
      .get(`web/user/orders/detail/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("setUserOrderDetail", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async cancelUserOrder({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`customer/orders/cancel/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async agreeUserOrder({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`customer/orders/confirm/${data.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async userReviewOrder({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post(`customer/orders/review/${data.order_id}`, data)
      .then((response) => {
        if (response.status === 200) {
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadUserOrdersFilters({
    commit
  }, data) {
    // commit("loadingStart", null);
    await this._vm.$http
      .get("web/user/orders/filters", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setUserOrdersFilters", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
};