import moment from "moment";
import i18n from '@/libs/i18n';

String.prototype.ArtoEn = function () {
  return this.replace(/[\u0660-\u0669]/g, (d) => d.charCodeAt() - 1632);
};

String.prototype.AMPM = function () {
  return this.replace("ص", "صباحاً").replace("م", "مساءً");
};

const formatDate = (date, format = " hh:mm A - YYYY/MM/DD") => {
  return moment(date).format(format).ArtoEn().AMPM();
};

const timeDifference = (date) => {
  return moment().diff(moment(date), "days") >= 7
    ? i18n.t("common.since") + moment(date).format("YYYY/MM/DD")
    : i18n.t("common.since") + moment(date).fromNow(true);
};
const getDate = (date) => {
  return moment().diff(moment(date), "days") >= 7
    ? moment(date).format("YYYY/MM/DD")
    : moment(date).fromNow(true);
};

const getDiff = (start, end) => {
  var startDate = moment(start, "YYYY-MM-DD");
  var endDate = moment(end, "YYYY-MM-DD");

  return endDate.diff(startDate, 'days') + 1;
};
export default {
  methods: {
    formatNumber(number, is_price = true) {
      let value = Intl.NumberFormat("en-IN", {
        maximumSignificantDigits: 3,
      }).format(number);
      if (is_price) {
        return value + " " + this.$t("common.sr");
      }
      return value;
    },
    formatDate,
    timeDifference,
    getDate,
    getDiff,
  },
};
