import authMiddleware from "../middleware/auth";
export default [
  {
    path: "/:lang",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            name: "home",
            component: () => import("@/views/pages/home.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideHeader: false,
              hideMobileBottomBar: false,
              breadcrumbs: [
                {
                  disabled: true,
                  path: "home",
                  title: "nav.home",
                },
              ],
            },
          },
          {
            path: "login",
            name: "login",
            component: () => import("@/views/authentication/login.vue"),
            meta: {
              hideFooter: true,
              hideNavbar: true,
              hideHeader: true,
              mobileRoute: true,
              hideMobileBottomBar: true,
            },
          },
          {
            path: "login-required",
            name: "login-required",
            component: () => import("@/views/authentication/LoginRequired.vue"),
            meta: {
              hideFooter: true,
              hideNavbar: true,
              hideHeader: true,
              mobileRoute: true,
              hideMobileBottomBar: true,
            },
          },
          {
            path: "phone-verification/:phone",
            name: "phone-verification",
            component: () =>
              import("@/views/authentication/phoneVerification.vue"),
            meta: {
              hideFooter: true,
              hideNavbar: true,
              hideHeader: true,
              mobileRoute: true,
              hideMobileBottomBar: true,
            },
          },
          {
            path: "complete-data",
            name: "complete-data",
            component: () => import("@/views/authentication/completeData.vue"),
            meta: {
              hideFooter: true,
              hideNavbar: true,
              hideHeader: true,
              mobileRoute: true,
              hideMobileBottomBar: true,
            },
          },
          {
            path: "notifications",
            name: "notifications",
            component: () => import("@/views/pages/notifications.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideHeader: false,
              hideMobileBottomBar: true,
              headerWithOutSlider: true,
              pageTitle: "pages.notifications",
              middleware: authMiddleware,
              breadcrumbs: [
                {
                  path: "home",
                  title: "nav.home",
                },
                {
                  disabled: true,
                  path: "notifications",
                  title: "pages.notifications",
                },
              ],
            },
          },
          {
            path: "chat",
            name: "chat",
            component: () => import("@/views/pages/chat.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideHeader: false,
              hideMobileBottomBar: true,
              headerWithOutSlider: true,
              pageTitle: "pages.chat",
              middleware: authMiddleware,
              breadcrumbs: [
                {
                  path: "home",
                  title: "nav.home",
                },
                {
                  disabled: true,
                  path: "chat",
                  title: "pages.chat",
                },
              ],
            },
          },
          {
            path: "privacy-policy",
            name: "privacy-policy",
            component: () => import("@/views/pages/privacyPolicy.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideHeader: false,
              hideMobileBottomBar: true,
              headerWithOutSlider: true,
              pageTitle: "pages.privacy_policy",
              breadcrumbs: [
                {
                  path: "home",
                  title: "nav.home",
                },
                {
                  disabled: true,
                  path: "privacy-policy",
                  title: "pages.privacy_policy",
                },
              ],
            },
          },
          {
            path: "terms-of-use",
            name: "terms-of-use",
            component: () => import("@/views/pages/termsOfUse.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideHeader: false,
              hideMobileBottomBar: true,
              headerWithOutSlider: true,
              pageTitle: "pages.terms_of_use",
              breadcrumbs: [
                {
                  path: "home",
                  title: "nav.home",
                },
                {
                  disabled: true,
                  path: "terms-of-use",
                  title: "pages.terms_of_use",
                },
              ],
            },
          },
          {
            path: "confirmation",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "confirmation",
                redirect: { name: "confirmation-vendors" },
              },
              {
                path: "vendors",
                name: "confirmation-vendors",
                component: () =>
                  import("@/views/pages/confirmation/confirmationVendors.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  middleware: authMiddleware,
                  pageTitle: "common.order_confirmation",
                },
              },
              {
                path: "talka-plus",
                name: "confirmation-talka-plus",
                component: () =>
                  import(
                    "@/views/pages/confirmation/confirmationTalkaPlus.vue"
                  ),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  pageTitle: "pages.talka_plus",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "talka-plus",
                      title: "pages.talka_plus",
                    },
                  ],
                },
              },
              {
                path: "medicine",
                name: "confirmation-medicine",
                component: () =>
                  import("@/views/pages/confirmation/confirmationMedicine.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  pageTitle: "pages.my_medicine",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "medicine",
                      title: "pages.my_medicine",
                    },
                  ],
                },
              },
              {
                path: "cars",
                name: "confirmation-cars",
                component: () =>
                  import("@/views/pages/confirmation/confirmationCars.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  pageTitle: "pages.cars",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "cars",
                      title: "pages.cars",
                    },
                  ],
                },
              },
              {
                path: "shipping",
                name: "confirmation-shipping",
                component: () =>
                  import("@/views/pages/confirmation/confirmationShipping.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  pageTitle: "pages.shipping",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "shipping",
                      title: "pages.shipping",
                    },
                  ],
                },
              },
              {
                path: "partners",
                name: "confirmation-partners",
                component: () =>
                  import("@/views/pages/confirmation/confirmationPartners.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideMobileBottomBar: true,
                  pageTitle: "common.order_confirmation",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "partners",
                      title: "pages.partners",
                    },
                  ],
                },
              },
            ],
          },
          {
            path: "search",
            name: "search",
            component: () => import("@/views/pages/search.vue"),
            meta: {
              hideFooter: false,
              hideNavbar: false,
              hideMobileBottomBar: true,
              smallHeightHeader: true,
              pageTitle: "pages.search",
              breadcrumbs: [
                {
                  path: "home",
                  title: "nav.home",
                },
                {
                  disabled: true,
                  path: "search",
                  title: "common.the_search",
                },
              ],
            },
          },
          {
            path: "account",
            component: () => import("@/views/pages/account/index.vue"),
            meta: {},
            children: [
              {
                path: "",
                name: "account",
                redirect: { name: "account-data" },
              },
              {
                path: "index",
                name: "account-index",
                component: () =>
                  import("@/views/pages/account/mobileIndex.vue"),
                meta: {
                  hideMobileBottomBar: false,
                  mediumHeightHeader: true,
                  mobileRoute: true,
                },
              },
              {
                path: "data",
                name: "account-data",
                component: () => import("@/views/pages/account/data.vue"),
                meta: {
                  hideFooter: false,
                  hideNavbar: false,
                  hideHeader: false,
                  hideMobileBottomBar: true,
                  headerWithOutSlider: true,
                  middleware: authMiddleware,
                  pageTitle: "pages.my_data_title",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "account",
                      title: "pages.my_account",
                    },
                  ],
                },
              },
              {
                path: "orders",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "",
                    name: "account-orders",
                    component: () =>
                      import("@/views/pages/account/myOrders.vue"),
                    meta: {
                      hideMobileBottomBar: false,
                      smallHeightHeader: true,
                      middleware: authMiddleware,
                      pageTitle: "pages.my_orders_title",
                      breadcrumbs: [
                        {
                          path: "home",
                          title: "nav.home",
                        },
                        {
                          disabled: true,
                          path: "account",
                          title: "pages.my_account",
                        },
                      ],
                    },
                  },
                  {
                    path: ":id",
                    name: "account-order-details",
                    component: () =>
                      import("@/views/pages/account/orderDetails.vue"),
                    meta: {
                      hideFooter: false,
                      hideNavbar: false,
                      hideHeader: false,
                      mobileRoute: true,
                      hideMobileBottomBar: true,
                      smallHeightHeader: true,
                      middleware: authMiddleware,
                      pageTitle: "common.order_details",
                    },
                  },
                ],
              },
              {
                path: "favorite",
                name: "account-favorite",
                component: () => import("@/views/pages/account/favorite.vue"),
                meta: {
                  hideMobileBottomBar: false,
                  smallHeightHeader: true,
                  pageTitle: "pages.favorite_title",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "account",
                      title: "pages.my_account",
                    },
                  ],
                },
              },
              {
                path: "cart",
                name: "account-cart",
                component: () => import("@/views/pages/account/cart.vue"),
                meta: {
                  hideMobileBottomBar: false,
                  smallHeightHeader: true,
                  pageTitle: "pages.cart_title",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "account",
                      title: "pages.my_account",
                    },
                  ],
                },
              },
              {
                path: "addresses",
                component: {
                  render(c) {
                    return c("router-view");
                  },
                },
                children: [
                  {
                    path: "",
                    name: "account-addresses",
                    component: () =>
                      import("@/views/pages/account/addresses.vue"),
                    meta: {
                      hideFooter: false,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                      headerWithOutSlider: true,
                      middleware: authMiddleware,
                      pageTitle: "pages.addresses_title",
                      breadcrumbs: [
                        {
                          path: "home",
                          title: "nav.home",
                        },
                        {
                          disabled: true,
                          path: "account",
                          title: "pages.my_account",
                        },
                      ],
                    },
                  },
                  {
                    path: "add",
                    name: "account-addresses-add",
                    component: () =>
                      import("@/views/pages/account/addAddress.vue"),
                    meta: {
                      hideFooter: false,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                      headerWithOutSlider: true,
                      mobileRoute: true,
                      middleware: authMiddleware,
                      pageTitle: "pages.add_a_new_address",
                    },
                  },
                  {
                    path: ":id",
                    name: "account-addresses-details",
                    component: () =>
                      import("@/views/pages/account/detailsAddress.vue"),
                    meta: {
                      hideFooter: false,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                      headerWithOutSlider: true,
                      mobileRoute: true,
                      middleware: authMiddleware,
                      pageTitle: "pages.address_details",
                    },
                  },
                ],
              },
              {
                path: "settings",
                name: "account-settings",
                component: () => import("@/views/pages/account/settings.vue"),
                meta: {
                  hideFooter: false,
                  hideNavbar: false,
                  hideHeader: false,
                  hideMobileBottomBar: true,
                  headerWithOutSlider: true,
                  pageTitle: "pages.settings",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "account",
                      title: "pages.my_account",
                    },
                  ],
                },
              },
              // {
              //   path: "technical-support",
              //   name: "account-technical-support",
              //   component: () => import("@/views/pages/account/favorite.vue"),
              //   meta: {
              //     breadcrumbs: [
              //       {
              //         path: "home",
              //         title: "nav.home",
              //       },
              //       {
              //         disabled: true,
              //         path: "account",
              //         title: "pages.my_account",
              //       },
              //     ],
              //   },
              // },
            ],
          },
          {
            path: "vendors/:category",
            component: {
              render(c) {
                return c("router-view");
              },
            },
            children: [
              {
                path: "",
                name: "vendors",
                component: () => import("@/views/pages/vendors/vendors.vue"),
                meta: {
                  hideFooter: false,
                  hideNavbar: false,
                  hideHeader: false,
                  hideMobileBottomBar: true,
                  pageTitle: "pages.restaurants",
                  breadcrumbs: [
                    {
                      path: "home",
                      title: "nav.home",
                    },
                    {
                      disabled: true,
                      path: "vendors",
                      title: "pages.restaurants",
                    },
                  ],
                },
              },
              {
                path: ":id",
                component: () => import("@/views/pages/vendors/details.vue"),
                meta: {
                  hideFooter: true,
                  hideNavbar: false,
                  hideHeader: false,
                  hideMobileBottomBar: true,
                },
                children: [
                  {
                    path: "",
                    name: "vendor-info",
                    redirect: { name: "vendor-products" },
                    meta: {
                      hideFooter: true,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                    },
                  },
                  {
                    path: "products",
                    component: {
                      render(c) {
                        return c("router-view");
                      },
                    },
                    children: [
                      {
                        path: "",
                        name: "vendor-products",
                        component: () =>
                          import("@/views/pages/vendors/products.vue"),
                        meta: {
                          hideFooter: true,
                          hideNavbar: false,
                          hideHeader: false,
                          hideMobileBottomBar: true,
                          headerWithOutSlider: true,
                          pageTitle: "",
                          breadcrumbs: [
                            {
                              path: "home",
                              title: "nav.home",
                            },
                            {
                              path: "vendors",
                              title: "pages.restaurants",
                            },
                            {
                              disabled: true,
                              path: "vendors/:id",
                              title: "",
                            },
                          ],
                        },
                      },
                      {
                        path: ":productId",
                        name: "mobile-order",
                        component: () =>
                          import("@/views/pages/vendors/mobileOrder.vue"),
                        meta: {
                          hideFooter: false,
                          hideNavbar: false,
                          mobileRoute: true,
                          hideHeader: false,
                          headerWithOutSlider: true,
                          hideMobileBottomBar: true,
                          pageTitle: "تشكن ستربس",
                        },
                      },
                    ],
                  },
                  {
                    path: "information",
                    name: "vendor-information",
                    component: () =>
                      import("@/views/pages/vendors/information.vue"),
                    meta: {
                      hideFooter: true,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                      pageTitle: "tab_routes.restaurant_information",
                      breadcrumbs: [
                        {
                          path: "home",
                          title: "nav.home",
                        },
                        {
                          path: "vendors",
                          title: "pages.restaurants",
                        },
                        {
                          disabled: true,
                          path: "vendors/:id",
                          title: "",
                        },
                      ],
                    },
                  },
                  {
                    path: "reviews",
                    name: "vendor-reviews",
                    component: () =>
                      import("@/views/pages/vendors/reviews.vue"),
                    meta: {
                      hideFooter: true,
                      hideNavbar: false,
                      hideHeader: false,
                      hideMobileBottomBar: true,
                      pageTitle: "tab_routes.reviews",
                      breadcrumbs: [
                        {
                          path: "home",
                          title: "nav.home",
                        },
                        {
                          path: "vendors",
                          title: "pages.restaurants",
                        },
                        {
                          disabled: true,
                          path: "vendors/:id",
                          title: "",
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
