export default {
  getlang(state) {
    return state.language;
  },
  getloading(state) {
    return state.loading;
  },
  getAddToCart(state) {
    return state.addedToCart;
  },
};
