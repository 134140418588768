export default {
  vendorCategory: null,
  vendors: null,
  vendorDetailData: null,
  vendorDetailProducts: null,
  vendorDetailInfo: null,
  vendorDetailReviews: null,
  productDetails: null,

};
