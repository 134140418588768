<template>
  <div>
    <h3 class="login-modal-title">{{ $t("common.verification_code") }}</h3>
    <p class="login-modal-subtitle">
      {{ $t("common.verification_code_subtitle") }}
    </p>
    <p class="login-modal-subtitle">
      {{ $t("common.to_your_mobile") }} <span dir="ltr"> +967{{ phone }}</span>
      <label @click="goBack()">{{ $t("common.edit") }}</label>
    </p>
    <WameedVerificationInput
      dir="ltr"
      @complete="(value) => (verificationCode = value)"
      @change="(value) => changeValue(value)"
      :fields="6"
      type="number"
      :fieldClasses="
        getCodeInvalid
          ? 'verification-input error-code mb-3'
          : getCodeValid ? 'verification-input success-code' :'verification-input'
      "
    />
    <p v-if="getCodeInvalid" class="text-reg-14 text-error mb-5">
      {{ $t("common.the_verification_code_is_incorrect") }}
    </p>
    <wameed-btn
      variant="secondary"
      :title="$t('common.verification')"
      classes="text-reg-14 px-4 text-white w-100"
      pill
      :disabled="verificationCode.length < 5 ? true : false"
      @onClick="submitAction()"
    />
    <div class="d-flex align-items-baseline justify-content-between mt-5">
      <p class="login-modal-subtitle mb-0">
        {{ $t("common.code_not_received") }}
        <WameedCounter
          :end="counterTime"
          @onFinish="codeNotReceived = true"
          style="color: #697485 !important; text-decoration: none !important"
        />
      </p>
      <wameed-btn
        :disabled="!codeNotReceived ? true : false"
        variant="white"
        :title="$t('common.resend')"
        classes="text-reg-14 px-4 text-primary"
        pill
        @onClick="resendFun"
        style="padding: 0; height: 20px; min-height: 20px; text-align: left"
        :class="!codeNotReceived ? 'resend-disabled' : ''"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  WameedBtn,
  WameedTextInput,
  WameedVerificationInput,
  WameedCounter,
} from "wameed-ui/dist/wameed-ui.esm";
export default {
  props: {
    phone: {
      type: String,
      default: "",
    },
    // verificationCode: {
    //   type: String,
    //   default: "",
    // },
    codeNotReceived: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WameedBtn,
    WameedTextInput,
    WameedCounter,
    WameedVerificationInput,
  },
  data() {
    return {
      counterTime: "180",
      verificationCode: "",
    };
  },
  computed: {
    ...mapGetters({
      getCodeInvalid: "getCodeInvalid",
      getCodeValid: "getCodeValid",
    }),
  },
  methods: {
    changeValue(value) {
      this.verificationCode = value;
      this.$emit("setOtpValue", this.verificationCode);
    },
    resendFun() {
      this.counterTime = "180";
      this.$emit("resend");
    },
    submitAction() {
      this.$emit("submitAction");
    },
    goBack() {
      this.$emit("goBack");
    },
  },
};
</script>
