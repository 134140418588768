<template>
  <footer>
    <div class="footer-top">
      <div>
        <h1 class="footer-top-title">
          {{ $t("common.footer_top_title") }}
        </h1>
        <p class="footer-top-subtitle">
          {{ $t("common.footer_top_subtitle") }}
        </p>
      </div>
      <div>
        <!-- <a
          href="#"
          class="footer-top-link ml-4"
          aria-label="app store"
          target="_blank"
          rel="noopener"
        >
          <app-store-icon />
        </a> -->
        <a
          href="https://play.google.com/store/apps/details?id=com.talka.express.customer"
          class="footer-top-link"
          aria-label="google play"
          target="_blank"
          rel="noopener"
        >
          <google-play-icon />
        </a>
      </div>
    </div>
    <b-row>
      <b-col md="5">
        <app-icon class="footer-logo" />
        <p
          class="footer-text"
          v-html="
            $t(
              'common.A company specialized in providing delivery and transportation services for various products at the level of individuals and institutions'
            )
          "
        ></p>
      </b-col>
      <b-col md="2" v-if="services && services.length > 0">
        <h1 class="footer-title">
          {{ $t("common.the_most_prominent_services") }}
        </h1>
        <div class="footer-list">
          <label
            v-if="
              services.filter(
                (item) => item.type == 1 && item.vendor_category == 1
              )[0]
            "
            @click="goToRoute('vendors', 1)"
            class="footer-list-link"
            style="cursor: pointer"
            >{{ $t("pages.restaurants") }}</label
          >
          <label
            v-if="services.filter((item) => item.type == 5)[0]"
            @click="goToRoute('confirmation-talka-plus', null)"
            class="footer-list-link"
            style="cursor: pointer"
            >{{ $t("pages.talka_plus") }}</label
          >
          <label
            v-if="services.filter((item) => item.type == 4)[0]"
            @click="goToRoute('confirmation-shipping', null)"
            class="footer-list-link"
            style="cursor: pointer"
            >{{ $t("pages.shipping") }}</label
          >
          <label
            v-if="
              services.filter(
                (item) => item.type == 1 && item.vendor_category == 3
              )[0]
            "
            @click="goToRoute('vendors', 3)"
            class="footer-list-link"
            style="cursor: pointer"
            >{{ $t("pages.booking") }}</label
          >
        </div>
      </b-col>
      <b-col md="2">
        <h1 class="footer-title">{{ $t("common.help") }}</h1>
        <div class="footer-list">
          <router-link :to="{ name: 'home' }" class="footer-list-link">{{
            $t("pages.about_me")
          }}</router-link>
          <router-link :to="{ name: 'home' }" class="footer-list-link">{{
            $t("pages.connect_us")
          }}</router-link>
          <router-link
            :to="{ name: 'terms-of-use' }"
            class="footer-list-link"
            >{{ $t("pages.terms_of_use") }}</router-link
          >
          <router-link
            :to="{ name: 'privacy-policy' }"
            class="footer-list-link"
            >{{ $t("pages.privacy_policy") }}</router-link
          >
        </div>
      </b-col>
      <b-col md="3">
        <h1 class="footer-title">
          {{ $t("common.connect_us") }}
        </h1>
        <div class="footer-list">
          <a href="tel:+967775605070" class="footer-list-link">
            <phone-footer-icon />
            <span class="mx-1" dir="ltr">+967 775 605 070</span></a
          >
          <a href="tel:+967775605070" class="footer-list-link">
            <phone-footer-icon />
            <span class="mx-1" dir="ltr">+967 775 605 070</span></a
          >
          <a href="mailto:talkaemail.info@gmail.com" class="footer-list-link">
            <email-footer-icon />
            talkaemail.info@gmail.com</a
          >
        </div>
        <div class="footer-social-list">
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="facebook"
            target="_blank"
            rel="noopener"
          >
            <facebook-icon />
          </a>
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="twitter"
            target="_blank"
            rel="noopener"
          >
            <twitter-icon />
          </a>
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="instagram"
            target="_blank"
            rel="noopener"
          >
            <instagram-icon />
          </a>
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="youtube"
            target="_blank"
            rel="noopener"
          >
            <youtube-icon />
          </a>
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="linkedin"
            target="_blank"
            rel="noopener"
          >
            <linkedin-icon />
          </a>
          <a
            href="#"
            class="footer-social-list-link"
            aria-label="tik tok"
            target="_blank"
            rel="noopener"
          >
            <tik-tok-icon />
          </a>
        </div>
      </b-col>
      <b-col cols="12">
        <hr class="footer-hr" />
      </b-col>
    </b-row>
    <b-row class="no-gutters justify-content-between">
      <b-col md="4">
        <h1 class="footer-rights">
          {{ $t("common.all_rights_reserved_to_Talka_platform") }}
        </h1>
      </b-col>
      <b-col md="4" class="text-left">
        <h1 class="footer-rights">
          <a
            href="https://wameed.co/ar"
            target="_blank"
            class="mr-1"
            aria-label="Wameed link"
            rel="noopener"
          >
            <developed-by-ar-icon v-if="$i18n.locale == 'ar'" />
            <developed-by-en-icon v-else />
          </a>
        </h1>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { BRow, BCol } from "bootstrap-vue";
export default {
  name: "Footer",
  components: {
    BRow,
    BCol,
  },
  data() {
    return {
      services: [],
    };
  },
  computed: {
    ...mapGetters({
      getHomeData: "getHomeData",
    }),
  },
  watch: {
    getHomeData(data) {
      this.services =
        data && data.services
          ? data.services.filter(
              (item) =>
                (item.type == 1 && item.vendor_category == 1) ||
                (item.type == 1 && item.vendor_category == 3) ||
                item.type == 4 ||
                item.type == 5
            )
          : [];
    },
  },
  methods: {
    goToRoute(routeName, category) {
      this.$router.push({
        name: routeName,
        params: { category: category },
      });

      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  },
};
</script>
