export default {
  async loadVendorCategory({ commit }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}`)
      .then((response) => {
        if (response.status === 200) {
          commit("setVendorCategory", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadVendors({ commit }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(
        `web/vendors/${data.vendor_type}/list?page=${data.page}&per_page=${data.per_page}&order_by=${data.order_by}&location=${data.location}
        &classification=${data.classification}&search=${data.search}`
      )
      .then((response) => {
        if (response.status === 200) {
          commit("setVendors", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadVendorDetailData({ commit }, data) {
    commit("setVendorDetailData", null);
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}/detail/${data.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("setVendorDetailData", response.data.data);
          // commit("loadingFinish");
          return true
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadVendorDetailProducts({ commit }, data) {
    commit("setVendorDetailProducts", null);
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}/detail/${data.id}/products`)
      .then((response) => {
        if (response.status === 200) {
          commit("setVendorDetailProducts", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadProductDetails({ commit }, data) {
    commit("setProductDetails", null);
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}/detail/${data.vendor_id}/products/${data.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit("setProductDetails", response.data.data);
          // commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadVendorDetailInfo({ commit }, data) {
    commit("setVendorDetailInfo", null);
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}/detail/${data.id}/info`)
      .then((response) => {
        if (response.status === 200) {
          commit("setVendorDetailInfo", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadVendorDetailReviews({ commit }, data) {
    commit("setVendorDetailReviews", null);
    // commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/vendors/${data.vendor_type}/detail/${data.id}/reviews`)
      .then((response) => {
        if (response.status === 200) {
          commit("setVendorDetailReviews", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
};
