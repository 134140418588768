<template>
  <div
    class="mobile-header"
    id="mobile-header"
    :class="
      $route.meta.headerWithOutSlider
        ? 'header-animation'
        : '' || $route.meta.smallHeightHeader
        ? $route.name == 'account-cart' && cartLength < 1
          ? 'header-animation'
          : 'small-height-header'
        : '' || $route.meta.mediumHeightHeader
        ? 'medium-height-header'
        : $route.name == 'vendors' &&
          getVendorCategory &&
          getVendorCategory.ads &&
          getVendorCategory.ads.length < 1
        ? 'header-animation'
        : ''
    "
    :style="
      $route.name == 'search' ||
      $route.name == 'account-orders' ||
      $route.name == 'account-favorite' ||
      $route.name == 'account-index' ||
      $route.name == 'account-order-details'
        ? 'height: 120px!important;clip-path: ellipse(100% 100% at 50% 0%)!important;'
        : ''
    "
  >
    <div
      class="w-100 d-flex justify-content-between"
      v-if="$route.name == 'home'"
    >
      <div>
        <h2 v-if="!homeScroll" class="mobile-header-welcome">
          {{ $t("common.welcome,_our_light") }} 😍
        </h2>
        <h2 v-if="homeScroll" class="mobile-header-welcome mb-0 mt-5">
          {{ $t("nav.home") }}
        </h2>
        <router-link
          v-if="!homeScroll && !getUserInfo"
          class="mobile-header-btn"
          :to="{ name: 'login' }"
          >{{ $t("common.please_login") }} <arrow-left-icon
        /></router-link>
        <wameed-btn
          v-if="!homeScroll && getUserInfo"
          variant="white"
          :title="
            getCustomerDeliveryTo
              ? $t('common.delivery_to_title') +
                ' : ' +
                getCustomerDeliveryTo.name
              : '-------' + ' : ' + $t('common.delivery_to_title')
          "
          classes="text-reg-11 p-0 text-white delivery-to-btn"
          appendIcon="arrow-down-icon"
          style="
            min-height: 25px !important;
            background-color: initial !important;
            border: none;
          "
          @onClick="deliveryToModal = !deliveryToModal"
        />

        <b-modal
          v-model="deliveryToModal"
          dialog-class="complete-order-content-modal"
          :no-close-on-backdrop="
            $mq != 'mobile' && $mq != 'tablet' ? true : false
          "
          no-fade
          hide-footer
        >
          <template #modal-header="{ close }">
            <button
              type="button"
              aria-label="Close"
              class="close"
              @click="close()"
            >
              <close-icon />
            </button>
            <h5 class="modal-title">{{ $t("common.delivery_to_title") }}</h5>
          </template>
          <div>
            <fieldset class="form-group custom-radio-with-icon mb-0">
              <div>
                <b-form-radio-group
                  v-if="
                    getUserAddresses &&
                    getUserAddresses.filter((item) => item.scope == 1).length >
                      0
                  "
                  id="radio-slots"
                  v-model="deliveryTo"
                  name="deliveryTo"
                >
                  <b-form-radio
                    v-for="(item, index) in getUserAddresses.filter(
                      (item) => item.scope == 1
                    )"
                    :key="index"
                    :value="item"
                    @input="saveDeliveryTo"
                  >
                    <div class="custom-control-label-icon">
                      <img v-lazy="item.icon" :alt="item.name" class="m-0" />
                      <!-- <office-icon /> -->
                    </div>
                    <div>
                      {{ item.name }}
                      <span v-if="item.is_default">
                        - {{ $t("common.default") }}
                      </span>
                      <span v-if="item.is_temp" class="text-secondary">
                        - {{ $t("common.is_temp") }}
                      </span>
                      <p class="custom-control-label-subtitle w-75">
                        {{ item.address }}
                      </p>
                    </div>
                  </b-form-radio>
                </b-form-radio-group>
                <button
                  type="button"
                  class="btn-border-dashed"
                  :class="
                    getUserAddresses &&
                    getUserAddresses.filter((item) => item.scope == 1).length >
                      3
                      ? 'mb-3'
                      : ''
                  "
                  @click="
                    goToRoute('account-addresses-add');
                    deliveryToModal = false;
                  "
                >
                  {{ $t("common.add_a_new_address") }}
                </button>
              </div>
            </fieldset>
          </div>
        </b-modal>
      </div>
      <router-link
        class="mobile-header-btn-notifications"
        :class="
          noReadNotifications &&
          getUserInfo &&
          getUserInfo.unread_notifications > 0
            ? 'unread_notifications'
            : ''
        "
        :to="{ name: 'notifications' }"
      >
        <div
          class="unread-notifications"
          v-if="
            noReadNotifications &&
            getUserInfo &&
            getUserInfo.unread_notifications > 0
          "
        >
          {{ getUserInfo.unread_notifications }}
        </div>
        <notification-bing-icon />
      </router-link>
    </div>
    <div
      class="account-data-header w-100"
      v-if="$route.name == 'account-index'"
    >
      <div class="account-tabs-info" v-if="getUserInfo">
        <img
          class="account-tabs-info-img"
          v-lazy="getUserInfo.image"
          :alt="getUserInfo.name"
        />
        <div>
          <h2 class="account-tabs-info-title">{{ getUserInfo.name }}</h2>
          <p class="account-tabs-info-subtitle" dir="ltr">
            {{ getUserInfo.phone }}
          </p>
        </div>
      </div>
      <wameed-btn
        v-else
        variant="secondary"
        :title="$t('common.sign_in')"
        classes="text-reg-14 px-4 text-white rounded-25 w-100 mt-3"
        @onClick="goToRoute('login')"
      />
    </div>
    <div
      class="w-100 d-flex justify-content-between"
      v-if="
        $route.name == 'account-orders' ||
        $route.name == 'account-favorite' ||
        $route.name == 'account-cart'
      "
    >
      <div>
        <h2 class="mobile-header-welcome mb-0 mt-5">
          {{ $t($route.meta.pageTitle) }}
        </h2>
      </div>
      <b-button
        v-if="cartLength > 0 && $route.name == 'account-cart'"
        variant="link"
        class="mobile-header-btn-trash"
        @click="deleteAllCartItemsModal = !deleteAllCartItemsModal"
      >
        <trash-icon />
      </b-button>
      <wameed-modal
        variant="error"
        variant2="error"
        icon-name="trash-icon"
        :visible="deleteAllCartItemsModal"
        :title="$t('common.delete_items_from_the_cart')"
        :sub-title="
          $t(
            'common.if_you_really_want_to_empty_the_items_from_the_cart_all_of_their_data_will_be_lost'
          )
        "
        :btn-title="$t('common.delete')"
        @close="deleteAllCartItemsModal = !deleteAllCartItemsModal"
        @submitAction="emptyCart"
      />
      <b-button
        v-if="favoriteLength > 0 && $route.name == 'account-favorite'"
        variant="link"
        class="mobile-header-btn-trash"
        @click="deleteAllFavoriteItemsModal = !deleteAllFavoriteItemsModal"
      >
        <trash-icon />
      </b-button>
      <wameed-modal
        variant="error"
        variant2="error"
        icon-name="trash-icon"
        :visible="deleteAllFavoriteItemsModal"
        :title="$t('common.delete_items_from_favorite')"
        :sub-title="
          $t(
            'common.if_you_really_want_to_delete_items_from_your_favorite_you_will_lose_all_of_their_data'
          )
        "
        :btn-title="$t('common.delete')"
        @close="deleteAllFavoriteItemsModal = !deleteAllFavoriteItemsModal"
        @submitAction="emptyFavorite"
      />
      <b-button
        v-if="
          getUserOrders &&
          getUserOrders.content.length > 0 &&
          $route.name == 'account-orders'
        "
        variant="link"
        class="mobile-header-btn-filter"
        :class="applyFilter ? 'bg-white' : ''"
        @click="filterModal = !filterModal"
      >
        <filter-icon />
      </b-button>

      <b-modal
        v-model="filterModal"
        dialog-class="complete-order-content-modal"
        :no-close-on-backdrop="
          $mq != 'mobile' && $mq != 'tablet' ? true : false
        "
        no-fade
      >
        <template #modal-header="{ close }">
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="close()"
          >
            <close-icon />
          </button>
          <h5 class="modal-title">{{ $t("common.filter_orders_by") }}</h5>
        </template>
        <div v-if="getUserOrdersFilters && getUserOrdersFilters.length > 0">
          <div
            class="product-modal-content-section-item"
            v-for="(item, index) in getUserOrdersFilters"
            :key="index"
            :class="index == 0 ? 'pt-4' : ''"
          >
            <WameedCheckbox
              :title="item.text"
              classes="product-checkbox-custom"
              :checked="filterData.type.includes(item) ? true : false"
              @onChange="
                index == 0 ? addAllToFilterData(item) : addToFilterData(item)
              "
            />
            <p class="product-modal-content-section-item-price">
              {{ item.items_count }}
            </p>
          </div>
        </div>
        <template #modal-footer="{ cancel }">
          <wameed-btn
            variant="secondary"
            :title="$t('common.apply') + ' (' + countOrders + ') '"
            classes="text-reg-14 px-4 text-white"
            pill
            :disabled="filterData.type.length < 1"
            @onClick="applyFilterFun"
            :block="applyFilter ? false : true"
          />
          <wameed-btn
            v-if="applyFilter"
            variant="disable"
            :title="$t('common.default')"
            classes="text-reg-14 px-4 text-font-secondary mr-auto"
            pill
            @onClick="defaultFilterFun"
          />
        </template>
      </b-modal>
    </div>
    <div
      class="w-100 d-flex"
      :class="!$route.meta.headerWithOutSlider ? 'justify-content-between' : ''"
      v-if="
        $route.name != 'home' &&
        $route.name != 'account-orders' &&
        $route.name != 'account-favorite' &&
        $route.name != 'account-cart' &&
        $route &&
        $route.meta &&
        $route.meta.pageTitle
      "
    >
      <b-button variant="link" class="mobile-header-back" @click="goBack()">
        <back-modal-icon />
      </b-button>

      <p
        class="mobile-header-title"
        v-if="$route && $route.meta.pageTitle == 'pages.restaurants'"
        :style="
          $route.name == 'account-addresses-details' ? 'left: 60%' : 'left: 50%'
        "
      >
        {{
          $route.meta.pageTitle == "pages.restaurants" &&
          $route.params.category == 1
            ? $t("pages.restaurants")
            : $t("pages.facilities")
        }}
      </p>
      <p
        class="mobile-header-title"
        v-if="$route && $route.meta.pageTitle != 'pages.restaurants'"
        :style="
          $route.name == 'account-addresses-details' ? 'left: 60%' : 'left: 50%'
        "
      >
        {{ $t($route.meta.pageTitle) }}
      </p>

      <b-button
        v-if="$route.name == 'account-order-details'"
        variant="link"
        class="mobile-header-btn-filter text-white"
        :class="applyFilter ? 'bg-white' : ''"
        style="width: auto; text-decoration: none"
        @click="updateOrderDetails"
      >
        <rotate-right-icon class="rotate-right-icon" />
        {{ $t("common.update") }}
      </b-button>
      <b-button
        v-if="
          $route.name == 'account-addresses-add' ||
          $route.name == 'account-addresses-details'
        "
        variant="link"
        class="mobile-header-back"
        :class="addressIsDefault ? 'text-primary bg-white' : 'text-white'"
        style="
          width: auto;
          text-decoration: auto;
          border-radius: 12px !important;
          margin-right: auto;
        "
        @click="addressIsDefaultFun"
      >
        {{ $t("common.default_2") }}
      </b-button>
      <b-button
        v-if="$route.name == 'account-addresses-details'"
        variant="link"
        class="mobile-header-btn-trash delete-address mr-3"
        @click="deleteAddressModal = !deleteAddressModal"
      >
        <trash-icon />
      </b-button>
      <wameed-modal
        variant="error"
        variant2="error"
        icon-name="trash-icon"
        :visible="deleteAddressModal"
        :title="$t('common.delete_address')"
        :sub-title="
          $t(
            'common.do_you_really_want_to_delete_this_address,_you_will_lose_all_its_data'
          )
        "
        :btn-title="$t('common.delete')"
        @close="deleteAddressModal = !deleteAddressModal"
        @submitAction="deleteAddressFun"
      />
      <b-button
        v-if="!$route.meta.headerWithOutSlider && $route.name == 'vendors'"
        variant="link"
        class="mobile-header-back"
        @click="goToRoute('search')"
      >
        <mobile-search-icon />
      </b-button>
      <WameedTextInput
        v-if="$route.name == 'search' && this.$route.query.title"
        fieldClasses="search-input"
        :placeholder="$t('common.search_your_favourites')"
        v-model="search"
        style="
          width: 82%;
          height: 44px;
          padding: 10px 16px;
          margin: 0;
          filter: none;
          border-radius: 14px;
        "
        :isAppend="true"
      >
        <template :slot="'append-slot'">
          <close-icon @click="removeSearch" />
        </template>
      </WameedTextInput>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BModal, BButton, BFormRadio, BFormRadioGroup } from "bootstrap-vue";
import {
  WameedTextArea,
  WameedTextInput,
  WameedNoData,
  WameedCheckbox,
  WameedBtn,
} from "wameed-ui/dist/wameed-ui.esm";
import wameedModal from "@/components/WameedModal.vue";
export default {
  name: "mobile-header",
  components: {
    BModal,
    BButton,
    WameedBtn,
    WameedCheckbox,
    wameedModal,
    BFormRadio,
    BFormRadioGroup,
  },
  data() {
    return {
      currentRouteMeta: null,
      addressIsDefault: false,
      homeScroll: false,
      filterModal: false,
      applyFilter: false,
      deliveryToModal: false,
      deleteAllCartItemsModal: false,
      deleteAllFavoriteItemsModal: false,
      deleteAddressModal: false,
      noReadNotifications: true,
      cartLength: 0,
      favoriteLength: 0,
      filterData: {
        type: [],
      },
      deliveryTo: null,
      search: null,
      showImage: true,
    };
  },
  computed: {
    ...mapGetters({
      getUserInfo: "getUserInfo",
      getUserOrders: "getUserOrders",
      getUserAddresses: "getUserAddresses",
      getUserOrdersFilters: "getUserOrdersFilters",
      getCustomerDeliveryTo: "getCustomerDeliveryTo",
      getVendorCategory: "getVendorCategory",
      getFavoriteVendors: "getFavoriteVendors",
      getFavoriteProducts: "getFavoriteProducts",
    }),

    countOrders() {
      let count = 0;
      this.filterData.type.forEach((item) => {
        count += item.items_count;
      });
      return count;
    },
  },
  watch: {
    getCustomerDeliveryTo(data) {
      this.deliveryTo = data;
    },
    getUserOrdersFilters(list) {
      if (list) {
        this.filterData.type.push(list[0]);
        let data = {
          filters: [],
        };
        this.filterData.type.forEach((item) => {
          data.filters.push(item.id);
        });
        this.loadUserOrders(data);
      }
    },
    "$route.query.title": function (search) {
      this.search = search;
    },
    search: function (search) {
      this.$route.query.title = search;
      window.dispatchEvent(
        new CustomEvent("talka-search", {
          detail: {
            search: this.search,
          },
        })
      );
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollFunction);
  },
  methods: {
    ...mapActions({
      loadUserOrders: "loadUserOrders",
      loadUserOrdersFilters: "loadUserOrdersFilters",
      deleteAddress: "deleteAddress",
      loadCustomerDeliveryTo: "loadCustomerDeliveryTo",
      loadUserOrderDetails: "loadUserOrderDetails",
      removeAllVendorsFromFavorite: "removeAllVendorsFromFavorite",
      removeAllProductsFromFavorite: "removeAllProductsFromFavorite",
    }),
    onError() {
      this.showImage = false;
    },
    saveDeliveryTo() {
      if (this.deliveryTo != null) {
        window.localStorage.removeItem("customerDeliveryTo");
        window.localStorage.setItem("customerID", this.getUserInfo.id);
        window.localStorage.setItem(
          "customerDeliveryTo",
          JSON.stringify(this.deliveryTo)
        );
        this.deliveryToModal = false;
        this.loadCustomerDeliveryTo();
      }
    },
    goToRoute(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
    removeSearch() {
      this.search = null;
      this.$route.query.title = null;
    },
    addToFilterData(value) {
      let firstItem = this.getUserOrdersFilters[0];
      if (this.filterData.type.includes(firstItem) == true) {
        var indexFirstItem = this.filterData.type.findIndex(
          (x) => x == firstItem
        );
        this.filterData.type.splice(indexFirstItem, 1);
      }
      if (this.filterData.type.includes(value) == true) {
        var index = this.filterData.type.findIndex((x) => x == value);
        this.filterData.type.splice(index, 1);
      } else {
        this.filterData.type.push(value);
      }
    },
    addAllToFilterData(value) {
      if (this.filterData.type.includes(value) == true) {
        var index = this.filterData.type.findIndex((x) => x == value);
        this.filterData.type.splice(index, 1);
      } else {
        this.filterData.type = [value];
      }
    },
    applyFilterFun() {
      this.filterModal = false;
      this.applyFilter = true;
      this.orderSelected = false;
      let data = {
        filters: [],
      };
      this.filterData.type.forEach((item) => {
        data.filters.push(item.id);
      });
      this.loadUserOrders(data);
    },
    defaultFilterFun() {
      this.filterModal = false;
      this.applyFilter = false;
      this.orderSelected = false;
      this.filterData.type.push(this.getUserOrdersFilters[0]);
      let data = {
        filters: [],
      };
      this.filterData.type.forEach((item) => {
        data.filters.push(item.id);
      });
      this.loadUserOrders(data);
    },
    goBack() {
      if (
        this.$route.name == "vendors" ||
        this.$route.name == "notifications" ||
        this.$route.name == "confirmation-talka-plus" ||
        this.$route.name == "confirmation-medicine" ||
        this.$route.name == "confirmation-cars" ||
        this.$route.name == "confirmation-shipping" ||
        this.$route.name == "confirmation-partners"
      ) {
        this.$router.push({
          name: "home",
        });
      } else if (
        this.$route.name == "vendor-info" ||
        this.$route.name == "vendor-products" ||
        this.$route.name == "vendor-information" ||
        this.$route.name == "vendor-reviews"
      ) {
        this.$router.push({
          name: "vendors",
        });
      } else if (
        this.$route.name == "search" ||
        this.$route.name == "mobile-order"
      ) {
        // parent.history.back();
        this.$router.push({
          name: "vendors",
          params: { category: 1 },
        });
      } else if (
        this.$route.name == "privacy-policy" ||
        this.$route.name == "terms-of-use"
      ) {
        this.$router.push({
          name: "account-settings",
        });
      } else if (this.$route.name == "account-order-details") {
        this.$router.push({
          name: "account-orders",
        });
      } else if (
        this.$route.name == "account-data" ||
        this.$route.name == "account-addresses" ||
        this.$route.name == "account-settings"
      ) {
        this.$router.push({
          name: "account-index",
        });
      } else if (
        this.$route.name == "account-addresses-add" ||
        this.$route.name == "account-addresses-details"
      ) {
        this.$router.push({
          name: "account-addresses",
        });
      } else {
        parent.history.back();
      }
    },
    emptyFavorite() {
      // localStorage.removeItem("talka_favorite");
      this.deleteAllFavoriteItemsModal = false;
      this.favoriteLength = 0;
      this.removeAllProductsFromFavorite();
      this.removeAllVendorsFromFavorite();
      window.dispatchEvent(
        new CustomEvent("talka-favorite-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("talka_favorite"),
          },
        })
      );
    },
    emptyCart() {
      localStorage.removeItem("talka_cart");
      this.deleteAllCartItemsModal = false;
      this.cartLength = 0;
      window.dispatchEvent(
        new CustomEvent("talka-cart-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("talka_cart"),
          },
        })
      );
    },
    scrollFunction() {
      const mobileHeader = document.getElementById("mobile-header");
      const header = document.getElementById("header");
      const tabControls = document.getElementById("vendors-nav-pills");
      const sliderOffers = document.getElementsByClassName("slider-offers");
      const vendorDetails = document.getElementById("vendor-details");
      const mobileOrder = document.getElementById("mobile-order");
      const mainCard = document.getElementsByClassName("main-card");
      const mainReviewsGlobal = document.getElementsByClassName(
        "main-reviews-global"
      );
      const orderConfirmation =
        document.getElementsByClassName("order-confirmation");
      const navTabsMyOrder = document.getElementById("nav-tabs-my-order");
      const mobileCart = document.getElementById("mobile-cart");
      if (mobileHeader) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          mobileHeader.classList.add("header-animation");
          navTabsMyOrder ? (navTabsMyOrder.style = "z-index: 110") : null;
        } else {
          if (!this.$route.meta.headerWithOutSlider) {
            mobileHeader.classList.remove("header-animation");
            navTabsMyOrder ? (navTabsMyOrder.style = "z-index: 110") : null;
          }
        }
      }
      if (header) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          this.homeScroll = true;
          // header.classList.add("opacity-header");
        } else {
          this.homeScroll = false;

          // header.classList.remove("opacity-header");
        }
      }
      if (tabControls) {
        if (
          document.body.scrollTop > 350 ||
          document.documentElement.scrollTop > 350
        ) {
          for (let index = 0; index < sliderOffers.length; index++) {
            const element = sliderOffers[index];
            element.style = "z-index: 0";
          }
          tabControls.classList.add("position-fixed-top");
        } else {
          for (let index = 0; index < sliderOffers.length; index++) {
            const element = sliderOffers[index];
            element.style = "z-index: 100";
          }
          tabControls.classList.remove("position-fixed-top");
        }
      }
      if (vendorDetails) {
        if (
          document.body.scrollTop > 550 ||
          document.documentElement.scrollTop > 550
        ) {
          vendorDetails.classList.add("div-animation");
        } else {
          vendorDetails.classList.remove("div-animation");
        }
      }
      if (mobileOrder) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          mobileOrder.classList.add("div-animation");
        } else {
          mobileOrder.classList.remove("div-animation");
        }
      }
      if (mainCard) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          for (let index = 0; index < mainCard.length; index++) {
            const element = mainCard[index];
            element.style = "z-index: 0";
          }
        } else {
          for (let index = 0; index < mainCard.length; index++) {
            const element = mainCard[index];
            element.style = "z-index: 110";
          }
        }
      }

      if (mainReviewsGlobal) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          for (let index = 0; index < mainReviewsGlobal.length; index++) {
            const element = mainReviewsGlobal[index];
            element.style = "z-index: 0";
          }
        } else {
          for (let index = 0; index < mainReviewsGlobal.length; index++) {
            const element = mainReviewsGlobal[index];
            element.style = "z-index: 110";
          }
        }
      }
      if (orderConfirmation) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          for (let index = 0; index < orderConfirmation.length; index++) {
            const element = orderConfirmation[index];
            element.style = "z-index: 0";
          }
        } else {
          for (let index = 0; index < orderConfirmation.length; index++) {
            const element = orderConfirmation[index];
            element.style = "z-index: 100";
          }
        }
      }
      if (mobileCart) {
        if (
          document.body.scrollTop > 10 ||
          document.documentElement.scrollTop > 10
        ) {
          mobileCart.style = "z-index: 0";
        } else {
          mobileCart.style = "z-index: 100";
        }
      }
    },

    addressIsDefaultFun() {
      this.addressIsDefault = !this.addressIsDefault;
      window.dispatchEvent(
        new CustomEvent("set-address-default", {
          detail: {
            storage: this.addressIsDefault,
          },
        })
      );
    },
    deleteAddressFun() {
      let data = {
        id: this.$route.params.id,
      };
      this.deleteAddress(data).then(() => {
        this.deleteAddressModal = false;
        this.$store.commit("successMessage", "delete_successfully", {
          root: true,
        });
        this.$router.push({
          name: "account-addresses",
        });
      });
    },

    updateOrderDetails() {
      let data = {
        id: this.$route.params.id,
      };
      this.loadUserOrderDetails(data);
    },
  },
  mounted() {
    window.addEventListener("talka-read-notifications", (event) => {
      this.noReadNotifications = event.detail.storage;
    });
    this.search = this.$route.query.title;
    window.addEventListener("set-address-default", (event) => {
      this.addressIsDefault = event.detail.storage;
    });
    const loginData = this.$cookies.get("customer-talka-login-data", {
      parseJSON: true,
    });
    if (loginData) {
      this.loadUserOrdersFilters();
    }
    this.cartLength = localStorage.talka_cart
      ? JSON.parse(localStorage.talka_cart).length
      : [];
    setTimeout(() => {
      this.favoriteLength =
        this.getFavoriteVendors && this.getFavoriteVendors.length > 0
          ? this.getFavoriteVendors.length
          : 0 + this.getFavoriteProducts && this.getFavoriteProducts.length > 0
          ? this.getFavoriteProducts.length
          : 0;
    }, 3000);
    window.addEventListener("talka-cart-localstorage-changed", (event) => {
      this.cartLength = localStorage.talka_cart
        ? JSON.parse(localStorage.talka_cart).length
        : [];
      this.favoriteLength =
        this.getFavoriteVendors && this.getFavoriteVendors.length > 0
          ? this.getFavoriteVendors.length
          : 0 + this.getFavoriteProducts && this.getFavoriteProducts.length > 0
          ? this.getFavoriteProducts.length
          : 0;
    });
    window.addEventListener("scroll", this.scrollFunction);
    this.currentRouteMeta = this.$route;
  },
};
</script>
