<template>
  <div id="full-layout">
    <div class="app-content content">
      <div class="content-wrapper">
        <div class="content-body">
          <Navbar v-if="!$route.meta.hideNavbar && $mq != 'mobile' && $mq != 'tablet'" />
          <MobileHeader
            v-if="($mq == 'mobile' || $mq == 'tablet') && !$route.meta.hideHeader"
          />
          <MobileBottomBar
            v-if="
              (!$route.meta.hideMobileBottomBar && $mq == 'mobile') || (!$route.meta.hideMobileBottomBar && $mq == 'tablet')
            "
          />
          <!-- <WameedBreadCrumbMobile v-if="$mq == 'mobile' || $mq == 'tablet'"/> -->
          <router-view />
          <Footer v-if="!$route.meta.hideFooter && $mq != 'mobile' && $mq != 'tablet'" />
          <div class="spinner-app" v-if="getloading">
            <b-spinner></b-spinner>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Navbar from "@/components/layouts/Navbar.vue";
import MobileHeader from "@/components/layouts/MobileHeader.vue";
import MobileBottomBar from "@/components/layouts/MobileBottomBar.vue";
import Footer from "@/components/layouts/Footer.vue";
import WameedBreadCrumbMobile from "@/components/WameedBreadCrumbMobile.vue";
import { BSpinner } from "bootstrap-vue";
export default {
  name: "FullLayout",
  components: {
    Navbar,
    MobileHeader,
    MobileBottomBar,
    Footer,
    WameedBreadCrumbMobile,
    BSpinner
  },
  computed: {
    ...mapGetters({
      getloading: "getloading",
    }),
  },
};
</script>
