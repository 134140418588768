<template>
  <b-navbar
    :toggleable="false"
    class="header-navbar navbar navbar-shadow align-items-center fixed-top w-100"
  >
    <div
      class="navbar-container d-flex content justify-content-between align-items-center h-100"
    >
      <!-- Nav Menu Toggler -->
      <div
        class="d-flex align-items-center justify-content-between navbar-start-items flex-grow-1 flex-lg-grow-0"
      >
        <ul class="nav navbar-nav px-0 d-flex align-items-center">
          <li class="nav-item">
            <b-link
              :to="{ name: 'home' }"
              class="nav-link"
              aria-label="Talka logo"
              rel="noopener"
            >
              <app-icon class="nav-link-logo" />
            </b-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'home' }" class="nav-link">{{
              $t("nav.home")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'account-orders' }" class="nav-link">{{
              $t("nav.my_orders")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'account-cart' }" class="nav-link">{{
              $t("nav.cart")
            }}</router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'account-favorite' }" class="nav-link">{{
              $t("nav.favorite")
            }}</router-link>
          </li>
          <li class="nav-item">
            <label
              @click="changeLanguage($route.params.lang == 'ar' ? 'en' : 'ar')"
              class="nav-link"
              >{{
                $route.params.lang == "ar"
                  ? $t("common.english")
                  : $t("common.arabic")
              }}</label
            >
          </li>
        </ul>
      </div>

      <b-navbar-nav class="nav align-items-center justify-content-center">
        <div class="d-flex header-btns" v-if="!getIsLoggedIn">
          <wameed-btn
            variant="primary"
            :title="$t('common.create_a_new_account')"
            classes="text-reg-14 px-5 border-1-white text-white rounded-12  delivery-to-btn"
            @onClick="loginModal = !loginModal"
          />
          <wameed-btn
            variant="secondary"
            :title="$t('common.sign_in')"
            classes="text-reg-14 px-4 text-white rounded-12"
            @onClick="loginModal = !loginModal"
          />
        </div>
        <div class="d-flex header-btns" v-if="getIsLoggedIn">
          <div
            class="rounded-12 bg-light-white text-reg-14 px-5 border-0-primary text-white rounded-12 browse-cart-btn"
            :class="cartLength > 0 ? 'items-in-cart' : ''"
            @click="goToRoute('account-cart')"
          >
            <div class="item-cart" v-if="cartLength > 0">
              {{ cartLength }}
            </div>
            <cart-icon />
            <p>{{ $t("common.browse_cart") }}</p>
          </div>
          <div
            class="rounded-12 bg-light-white text-reg-14 px-5 border-0-primary text-white rounded-12 notification-btn"
            :class="getUnreadMessagesCount > 0 ? 'unread_notifications' : ''"
            @click="goToRoute('chat')"
          >
            <div class="unread-notifications" v-if="getUnreadMessagesCount > 0">
              {{ getUnreadMessagesCount }}
            </div>
            <chat-icon />
          </div>
          <div
            class="rounded-12 bg-light-white text-reg-14 px-5 border-0-primary text-white rounded-12 notification-btn"
            :class="
              noReadNotifications &&
              getUserInfo &&
              getUserInfo.unread_notifications > 0
                ? 'unread_notifications'
                : ''
            "
            @click="goToRoute('notifications')"
          >
            <div
              class="unread-notifications"
              v-if="
                noReadNotifications &&
                getUserInfo &&
                getUserInfo.unread_notifications > 0
              "
            >
              {{ getUserInfo.unread_notifications }}
            </div>
            <notification-bing-icon />
          </div>

          <!-- <wameed-btn
            variant="light-white"
            title=""
            appendIcon="notification-bing-icon"
            :classes="
              getUserInfo && getUserInfo.unread_notifications > 0
                ? 'text-reg-14 px-5 border-0-primary text-white rounded-12 notification-btn unread_notifications'
                : 'text-reg-14 px-5 border-0-primary text-white rounded-12 notification-btn'
            "
            @onClick="goToRoute('notifications')"
          /> -->
          <b-dropdown
            v-if="getUserInfo"
            id="dropdown-left"
            variant="inherit"
            class="rounded-12 user-card-data"
          >
            <template #button-content>
              <div
                v-if="getUserInfo"
                class="d-flex justify-content-center align-items-center w-100"
                @click="showList = !showList"
              >
                <img
                  class="user-card-data-img"
                  v-lazy="getUserInfo.image"
                  :alt="getUserInfo.name"
                />
                <div>
                  <p class="user-card-data-name">{{ getUserInfo.name }}</p>
                  <p class="user-card-data-phone">{{ getUserInfo.phone }}</p>
                </div>
                <arrow-down-icon />
              </div>
            </template>
            <b-dropdown-item href="#"
              ><wameed-btn
                variant="white"
                :title="$t('common.control_board')"
                classes="text-reg-14 px-5 border-1-white text-primary w-100 text-end delivery-to-btn"
                @onClick="goToRoute('account-data')"
              />
            </b-dropdown-item>
            <b-dropdown-item href="#">
              <wameed-btn
                variant="white"
                :title="$t('common.sign_out')"
                classes="text-reg-14 px-5 border-1-white text-primary w-100 text-end delivery-to-btn"
                @onClick="signOutFun"
            /></b-dropdown-item>
          </b-dropdown>
          <!-- <div class="rounded-12 user-card-data">
            <div class="user-card-data-list" v-if="showList"></div>
          </div> -->
        </div>
      </b-navbar-nav>
    </div>
    <div class="sub-navbar">
      <ul class="nav navbar-nav px-0">
        <li v-if="getIsLoggedIn">
          <wameed-btn
            variant="white"
            :title="
              getCustomerDeliveryTo
                ? $t('common.delivery_to_title') +
                  ' : ' +
                  getCustomerDeliveryTo.name
                : '-------' + ' : ' + $t('common.delivery_to_title')
            "
            :classes="
              getCustomerDeliveryTo
                ? 'text-reg-14 px-5 border-1-primary text-primary rounded-12  delivery-to-btn'
                : 'text-reg-14 px-5 border-1-primary text-primary rounded-12  delivery-to-btn dir-ltr'
            "
            appendIcon="arrow-down-icon"
            @onClick="deliveryToModal = !deliveryToModal"
          />
          <b-modal
            v-model="deliveryToModal"
            dialog-class="complete-order-content-modal"
            :no-close-on-backdrop="
              $mq != 'mobile' && $mq != 'tablet' ? true : false
            "
            no-fade
          >
            <template #modal-header="{ close }">
              <button
                type="button"
                aria-label="Close"
                class="close"
                @click="close()"
              >
                <close-icon />
              </button>
              <h5 class="modal-title">{{ $t("common.delivery_to_title") }}</h5>
            </template>
            <div>
              <fieldset class="form-group custom-radio-with-icon mb-0">
                <div>
                  <b-form-radio-group
                    v-if="
                      getUserAddresses &&
                      getUserAddresses.filter((item) => item.scope == 1)
                        .length > 0
                    "
                    id="radio-slots"
                    v-model="deliveryTo"
                    name="deliveryTo"
                  >
                    <b-form-radio
                      v-for="(item, index) in getUserAddresses.filter(
                        (item) => item.scope == 1
                      )"
                      :key="index"
                      :value="item"
                    >
                      <div class="custom-control-label-icon">
                        <img v-lazy="item.icon" :alt="item.name" class="m-0" />
                        <!-- <office-icon /> -->
                      </div>
                      <div>
                        {{ item.name }}
                        <span v-if="item.is_default">
                          - {{ $t("common.default") }}
                        </span>
                        <span v-if="item.is_temp" class="text-secondary">
                          - {{ $t("common.is_temp") }}
                        </span>
                        <p class="custom-control-label-subtitle w-75">
                          {{ item.address }}
                        </p>
                      </div>
                    </b-form-radio>
                  </b-form-radio-group>
                  <button
                    type="button"
                    class="btn-border-dashed"
                    :class="
                      getUserAddresses &&
                      getUserAddresses.filter((item) => item.scope == 1)
                        .length > 3
                        ? 'mb-3'
                        : ''
                    "
                    @click="
                      goToRoute('account-addresses');
                      deliveryToModal = false;
                    "
                  >
                    {{ $t("common.add_a_new_address") }}
                  </button>
                </div>
              </fieldset>
            </div>
            <template #modal-footer="{ cancel }">
              <wameed-btn
                variant="secondary"
                :title="$t('common.ok')"
                classes="text-reg-14 px-4 text-white"
                pill
                @onClick="saveDeliveryTo"
              />
              <wameed-btn
                variant="disable"
                :title="$t('common.cancel')"
                classes="text-reg-14 px-4 text-font-secondary mr-auto"
                pill
                @onClick="cancel()"
              />
            </template>
          </b-modal>
        </li>
        <li v-if="!getIsLoggedIn">
          <wameed-btn
            variant="white"
            :title="$t('common.Please login and select the delivery address')"
            :classes="'text-reg-14 px-5 border-1-primary text-primary rounded-12  delivery-to-btn'"
            appendIcon="arrow-down-icon"
            @onClick="loginModal = !loginModal"
          />
        </li>
        <li class="nav-item mr-auto position-relative">
          <WameedTextInput
            v-model="search"
            :fieldClasses="
              showSearchHistory
                ? 'nav-item-search nav-item-search-z-index'
                : 'nav-item-search'
            "
            :placeholder="$t('common.search')"
            :isAppend="true"
            @onFocus="showSearchHistory = true"
            @onKeypress="addValueToSearchHistory"
            autocomplete="off"
          >
            <template :slot="'append-slot'">
              <search-icon />
            </template>
          </WameedTextInput>
          <div
            class="search-history-overly"
            v-if="showSearchHistory"
            @click="showSearchHistory = false"
          ></div>
          <div class="search-history" v-if="showSearchHistory">
            <div
              class="d-flex align-items-center justify-content-between"
              v-if="searchHistory.length > 0"
            >
              <h4 class="search-history-title">
                {{ $t("common.search_history") }}
              </h4>
              <label
                class="search-history-subtitle"
                @click="removeAllSearchHistory"
                >{{ $t("common.delete_history") }}</label
              >
            </div>
            <div class="search-history-content" v-if="searchHistory.length > 0">
              <div
                class="search-history-content-item"
                v-for="(item, index) in searchHistory"
                :key="index"
              >
                <h4
                  class="search-history-content-item-title"
                  @click="addValueToSearchInput(item)"
                >
                  {{ item }}
                </h4>
                <label
                  class="search-history-content-item-btn"
                  @click="removeItemSearchHistory(item)"
                >
                  <delete-search-history-icon />
                </label>
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <h4 class="search-history-title">
                {{ $t("common.proposal") }} <fire-icon />
              </h4>
            </div>
            <div>
              <label
                v-for="(item, index) in getSuggestKeywords"
                :key="index"
                class="search-history-searched-item"
                @click="addValueToSearchInput(item.name)"
                >{{ item.name }}</label
              >
            </div>
          </div>
        </li>
      </ul>

      <b-modal
        :no-close-on-backdrop="
          $mq != 'mobile' && $mq != 'tablet' ? true : false
        "
        v-model="loginModal"
        hide-footer
        modal-class="login-modal"
      >
        <template #modal-header="{ close }">
          <b-button variant="link" class="login-modal-close" @click="close()">
            <close-icon />
          </b-button>
          <vector-login-icon class="login-modal-vector" />
        </template>

        <template #default="{ hide }">
          <h3 class="login-modal-title">{{ $t("common.sign_in") }}</h3>
          <p class="login-modal-subtitle">
            {{ $t("common.sign_in_subtitle") }} 👋
          </p>
          <LoginForm
            @submitAction="submitGetOtp"
            @continueWithoutSignIn="loginModal = !loginModal"
            @setPhoneValue="changePhoneValueFun"
          />
        </template>
      </b-modal>
      <b-modal
        :no-close-on-backdrop="
          $mq != 'mobile' && $mq != 'tablet' ? true : false
        "
        v-model="verificationCodeModal"
        hide-footer
        modal-class="login-modal verification-modal"
      >
        <template #modal-header="{ close }">
          <b-button
            variant="link"
            class="login-modal-back"
            @click="
              verificationCodeModal = !verificationCodeModal;
              loginModal = !loginModal;
            "
          >
            <back-modal-icon />
          </b-button>
          <vector-verification-code-icon class="login-modal-vector" />
        </template>

        <template #default="{ hide }">
          <PhoneVerificationForm
            :phone="phone"
            :verification-code="verification_code"
            :code-not-received="codeNotReceived"
            @submitAction="submitLogin"
            @goBack="
              verificationCodeModal = !verificationCodeModal;
              loginModal = !loginModal;
            "
            @resend="submitGetOtp"
            @setOtpValue="changeOtpValueFun"
          />
        </template>
      </b-modal>
      <b-modal
        :no-close-on-backdrop="
          $mq != 'mobile' && $mq != 'tablet' ? true : false
        "
        v-model="registerModal"
        hide-footer
        modal-class="login-modal"
        size="lg"
      >
        <template #modal-header="{ close }">
          <!-- Emulate built in modal header close button action -->
          <b-button variant="link" class="login-modal-close" @click="close()">
            <close-icon />
          </b-button>
          <vector-login-icon class="login-modal-vector" />
        </template>

        <template #default="{ hide }">
          <h3 class="login-modal-title">{{ $t("common.register") }}</h3>
          <p class="login-modal-subtitle">
            {{ $t("common.register_subtitle") }} 😍
          </p>
          <RegisterForm
            typeDatePicker="web"
            @submitAction="submitCompleteData"
            @setUserData="changeUserDataFun"
          />
          <div class="register-modal-footer">
            <p class="register-modal-footer-text">
              {{ $t("common.you_agree") }}
              <br />
              {{ $t("common.on") }}
              <label @click="goToRoute('terms-of-use')">{{
                $t("pages.terms_of_use")
              }}</label>
              {{ $t("common.and") }}
              <label @click="goToRoute('privacy-policy')">{{
                $t("pages.privacy_policy")
              }}</label>
            </p>
          </div>
        </template>
      </b-modal>
    </div>
    <wameed-modal
      variant="error"
      variant2="error"
      icon-name="sign-out-icon"
      :visible="signOutModal"
      :title="$t('common.sign_out')"
      :sub-title="$t('common.sign_out_subtitle')"
      :btn-title="$t('common.sign_out')"
      @close="signOutModal = !signOutModal"
      @submitAction="logout"
    />
  </b-navbar>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  BNavbar,
  BDropdown,
  BDropdownItem,
  BNavbarNav,
  BLink,
  BModal,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import {
  WameedBtn,
  WameedTextInput,
  WameedPhoneInput,
  WameedVerificationInput,
  WameedCounter,
  WameedDatePicker,
} from "wameed-ui/dist/wameed-ui.esm";
import LoginForm from "@/components/auth/Login.vue";
import PhoneVerificationForm from "@/components/auth/PhoneVerification.vue";
import RegisterForm from "@/components/auth/Register.vue";
import wameedModal from "@/components/WameedModal.vue";
export default {
  name: "Navbar",
  components: {
    BNavbar,
    BDropdown,
    BDropdownItem,
    BNavbarNav,
    BLink,
    BModal,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    WameedBtn,
    WameedPhoneInput,
    WameedTextInput,
    WameedVerificationInput,
    WameedCounter,
    WameedDatePicker,
    LoginForm,
    PhoneVerificationForm,
    RegisterForm,
    wameedModal,
  },
  data() {
    return {
      loginModal: false,
      signOutModal: false,
      verificationCodeModal: false,
      registerModal: false,
      codeNotReceived: false,
      showSearchHistory: false,
      deliveryToModal: false,
      // getIsLoggedIn: false,
      showList: false,
      noReadNotifications: true,
      cartLength: 0,
      searchHistory: [],
      phone: "",
      otp: "",
      verification_code: "",
      search: "",
      userData: {
        name: "",
        email: "",
        birth_date: "",
        gender: "",
        image: "",
      },
      deliveryTo: null,
      showImage: true,
    };
  },
  computed: {
    ...mapGetters({
      getIsLoggedIn: "getIsLoggedIn",
      getUserInfo: "getUserInfo",
      getUserAddresses: "getUserAddresses",
      getCustomerDeliveryTo: "getCustomerDeliveryTo",
      getSuggestKeywords: "getSuggestKeywords",
      getCodeInvalid: "getCodeInvalid",
      getUnreadMessagesCount: "chat/getUnreadMessagesCount",
    }),
  },
  watch: {
    getCustomerDeliveryTo(data) {
      this.deliveryTo = data;
    },
  },
  methods: {
    ...mapActions({
      getOtp: "getOtp",
      login: "login",
      completeData: "completeData",
      logout: "logout",
      loadCustomerDeliveryTo: "loadCustomerDeliveryTo",
      loadSuggestKeywords: "loadSuggestKeywords",
    }),
    onError() {
      this.showImage = false;
    },
    saveDeliveryTo() {
      if (this.deliveryTo != null) {
        window.localStorage.removeItem("customerDeliveryTo");
        window.localStorage.setItem("customerID", this.getUserInfo.id);
        window.localStorage.setItem(
          "customerDeliveryTo",
          JSON.stringify(this.deliveryTo)
        );
        this.deliveryToModal = false;
        this.loadCustomerDeliveryTo();
      }
    },
    changePhoneValueFun(data) {
      this.phone = data;
    },
    changeOtpValueFun(data) {
      this.otp = data;
    },
    changeUserDataFun(data) {
      this.userData.name = data.name;
      this.userData.email = data.email;
      this.userData.birth_date = data.birth_date;
      this.userData.gender = data.gender;
    },
    submitGetOtp() {
      var data = {
        code: "+967",
        phone: this.phone,
      };
      this.getOtp(data).then(() => {
        this.loginModal = false;
        this.verificationCodeModal = true;
      });
    },
    submitLogin() {
      var data = {
        code: "+967",
        otp: this.otp,
        phone: this.phone,
      };
      this.login(data).then((res) => {
        if (!res.data.completed_register_at) {
          setTimeout(() => {
            this.registerModal = true;
            this.verificationCodeModal = false;
          }, 1500);
        } else {
          setTimeout(() => {
            this.verificationCodeModal = false;
            window.location.reload();
          }, 1500);
        }
      });
    },
    submitCompleteData() {
      this.completeData(this.userData).then((res) => {
        this.registerModal = false;
        window.location.reload();
      });
    },
    goToRoute(routeName) {
      this.$router.push({
        name: routeName,
      });
      this.registerModal = false;
      this.showList = false;
    },
    signOutFun() {
      this.signOutModal = !this.signOutModal;
      this.showList = false;
    },
    addValueToSearchInput(value) {
      this.search = value;
      this.showSearchHistory = false;
      this.canClose = false;
      this.$router.push({
        name: "search",
        query: { title: value },
      });
    },
    onFocuseOut(e) {
      setTimeout(() => {
        if (this.canClose) {
          this.showSearchHistory = false;
        }
        this.canClose = true;
      }, 100);
    },
    addValueToSearchHistory(e) {
      if (e.keyCode === 13) {
        e.preventDefault(); // Ensure it is only this code that runs
        var searchHistory = localStorage.talka_search_history
          ? JSON.parse(localStorage.talka_search_history)
          : [];

        if (!searchHistory.includes(this.search) && this.search != "") {
          searchHistory.push(this.search);
          localStorage.talka_search_history = JSON.stringify(searchHistory);
        }

        this.searchHistory = localStorage.talka_search_history
          ? JSON.parse(localStorage.talka_search_history)
          : [];
        this.showSearchHistory = false;

        this.$router.push({
          name: "search",
          query: { title: this.search },
        });
        this.search = "";
      }
    },
    removeItemSearchHistory(value) {
      var searchHistory = localStorage.talka_search_history
        ? JSON.parse(localStorage.talka_search_history)
        : [];
      searchHistory = searchHistory.filter(function (item) {
        return item !== value;
      });
      localStorage.talka_search_history = JSON.stringify(searchHistory);
      this.searchHistory = localStorage.talka_search_history
        ? JSON.parse(localStorage.talka_search_history)
        : [];
    },
    removeAllSearchHistory() {
      localStorage.removeItem("talka_search_history");
      this.searchHistory = localStorage.talka_search_history
        ? JSON.parse(localStorage.talka_search_history)
        : [];
    },
    changeLanguage(lang) {
      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {
          lang: lang,
        },
      });
      this.$i18n.locale = lang;
      this.languageModal = false;
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
  },
  mounted() {
    this.loadSuggestKeywords();
    this.searchHistory = localStorage.talka_search_history
      ? JSON.parse(localStorage.talka_search_history)
      : [];
    this.deliveryTo = this.getCustomerDeliveryTo;
    this.cartLength = localStorage.talka_cart
      ? JSON.parse(localStorage.talka_cart).length
      : [];
    window.addEventListener("talka-cart-localstorage-changed", (event) => {
      this.cartLength = localStorage.talka_cart
        ? JSON.parse(localStorage.talka_cart).length
        : [];
    });
    window.addEventListener("talka-read-notifications", (event) => {
      this.noReadNotifications = event.detail.storage;
    });
    // const loginData = this.$cookies.get("customer-talka-login-data", {
    //   parseJSON: true,
    // });
    // if (loginData) {
    //   this.isLogin = true;
    // }
  },
};
</script>
