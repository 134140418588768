import Vue from "vue";
import Vuex from "vuex";

// Modules
import app from "./app";
import home from "./home";
import vendors from "./vendors";
import auth from "./auth";
import profile from "./profile";
import favorite from "./favorite";
import chat from "./chat";
import utilities from "./utilities";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    home,
    vendors,
    auth,
    profile,
    favorite,
    chat,
    utilities,
  },
  strict: process.env.DEV,
});
