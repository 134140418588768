<template>
  <div>
    <wameed-form class="w-100" @onSubmit="submitAction">
      <template v-slot="{ invalid }">
        <b-row>
          <b-col md="6" class="mt-md-5 mt-0">
            <WameedTextInput
              v-model="userData.name"
              fieldClasses=""
              :label="$t('from.user_name')"
              :placeholder="$t('from.user_name_placeholder')"
              type="text"
              @input="changeData"
            />
          </b-col>
          <b-col md="6" class="mt-md-5 mt-0">
            <WameedTextInput
              v-model="userData.email"
              fieldClasses=""
              :label="$t('from.email')"
              :subLable="$t('from.optional')"
              :placeholder="$t('from.email_placeholder')"
              type="email"
              :name="$t('from.email')"
              rules="email"
              @input="changeData"
            />
          </b-col>
          <b-col md="6">
            <WameedDatePicker
              v-if="typeDatePicker == 'web'"
              v-model="userData.birth_date"
              fieldClasses=""
              :label="$t('from.birth_date')"
              :subLable="$t('from.optional')"
              :placeholder="$t('from.birth_date_placeholder')"
              :minDate="'1/1/1950'"
              :locale="$i18n.locale"
              :config="{
                type: 'string',
                mask: 'YYYY/MM/DD',
              }"
              @input="changeData"
            />
            <Scrolldatepicker
              v-if="typeDatePicker == 'mobile'"
              :label="$t('from.birth_date')"
              :subLable="$t('from.optional')"
              :placeholder="$t('from.birth_date_placeholder')"
              v-model="userData.birth_date"
              :theme-color="'#8D98AA'"
              :min="'1950-01-01'"
              :input-class="'form-control'"
              :input-style="{ color: '#484F5B' }"
              @change-month="changeData"
              @change-year="changeData"
              @change-decade="changeData"
            />
          </b-col>
          <b-col md="6">
            <fieldset class="form-group wameed-input text-reg-14">
              <div>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <label class="text-font-main">{{ $t("from.gender") }}</label>
                  <b-form-radio-group
                    id="radio-slots"
                    v-model="userData.gender"
                    :aria-describedby="ariaDescribedby"
                    name="gender"
                    @change="changeData"
                  >
                    <b-form-radio value="1">{{ $t("from.male") }}</b-form-radio>
                    <b-form-radio value="0">{{
                      $t("from.female")
                    }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </div>
            </fieldset>
          </b-col>
        </b-row>

        <wameed-btn
          variant="secondary"
          :title="$t('common.register')"
          classes="text-reg-14 px-4 text-white w-100"
          pill
          :disabled="
            invalid || userData.name == '' || userData.gender == ''
              ? true
              : false
          "
          type="submit"
        />
      </template>
    </wameed-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
} from "bootstrap-vue";
import Scrolldatepicker from "@/components/Scrolldatepicker";
import {
  WameedForm,
  WameedBtn,
  WameedTextInput,
  WameedDatePicker,
} from "wameed-ui/dist/wameed-ui.esm";

export default {
  props: {
    typeDatePicker: {
      type: String,
      default: "",
    },
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    WameedBtn,
    WameedTextInput,
    WameedDatePicker,
    Scrolldatepicker,
    WameedForm
  },
  data() {
    return {
      userData: {
        name: "",
        email: "",
        birth_date: "",
        gender: "",
      },
    };
  },
  methods: {
    changeData() {
      this.$emit("setUserData", this.userData);
    },
    submitAction() {
      this.$emit("submitAction");
    },
    continueWithoutSignIn() {
      this.$emit("continueWithoutSignIn");
    },
  },
};
</script>
<style lang="scss">
@import "node_modules/vue-scroll-datepicker-cashbac/assets/scrolldatepicker.scss";
</style>
