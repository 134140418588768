export default {
  info: null,
  privacy: null,
  terms: null,
  customerDeliveryTo: null,
  paymentMethods: null,
  vehicleTypes: null,
  orderTypes: null,
  shippingBoundaries: null,
  discounts: null,
  coupon: null,
  search: null,
  searchProducts: null,
  countries: null,
  cities: null,
};
