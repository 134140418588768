import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import i18n from "@/libs/i18n";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP,
    libraries: "places",
    region: i18n.locale,
    language: i18n.locale,
  },
});
