// import axios from '@/plugins/axios';
// import i18n from '@/libs/i18n';
// import router from '@/router';
import store from "@/store/index";
export default {
  async loadInfo({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(`web/info`, data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("setInfo", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadSearch({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(
        `web/search?page=${data.page}&per_page=${data.per_page}&search=${data.search}&type=vendors`,
        data
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setSearch", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadSearchProducts({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get(
        `web/search?page=${data.page}&per_page=${data.per_page}&search=${data.search}&type=products`,
        data
      )
      .then((respo) => {
        if (respo.status === 200) {
          commit("setSearchProducts", respo.data.data);
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async uploadFiles({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("web/upload-files", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          return respo.data.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadPrivacy({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("web/page/privacy", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setPrivacy", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadTerms({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("web/page/terms", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setTerms", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadCustomerDeliveryTo({
    commit
  }, data) {
    setTimeout(() => {
      let customerDeliveryTo =
        window.localStorage.getItem("customerDeliveryTo");
      let customerID = window.localStorage.getItem("customerID");
      if (store.state.profile.userInfo && customerDeliveryTo && customerID == store.state.profile.userInfo.id) {
        commit("setCustomerDeliveryTo", JSON.parse(customerDeliveryTo));
      }
    }, 1000);
  },
  async loadPaymentMethods({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("web/user/orders/payment-methods", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setPaymentMethods", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadVehicleTypes({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("web/user/orders/vehicle-types", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setVehicleTypes", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadOrderTypes({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("web/user/orders/order-types", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setOrderTypes", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadShippingBoundaries({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .get("web/user/orders/shipping-boundaries", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setShippingBoundaries", response.data.data);
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async loadDiscounts({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("customer/orders/get-discounts", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setDiscounts", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async sendOrder({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("customer/orders/send", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setDiscounts", response.data.data);
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async sendCoupon({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("customer/orders/coupon", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setCoupon", response.data.data);
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },


  async loadCountries({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("customer/v2/user/orders/countries", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setCountries", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  async loadCities({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .get("customer/v2/user/orders/cities/yemen", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setCities", response.data.data);
          commit("loadingFinish");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },

  
};