<template>
  <div class="bread-crumb-mobile" v-if="$route && $route.meta && $route.meta.pageTitle">
    <div>
      <b-button variant="link" class="bread-crumb-mobile-back" @click="goBack()">
        <back-modal-icon />
      </b-button>
    </div>
    <p class="bread-crumb-mobile-title" v-if="$route">
      {{ $t($route.meta.pageTitle) }}
    </p>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";

export default {
  name: "BreadCrumbMobile",
  components: {
    BButton,
  },
  data() {
    return {
      currentRouteMeta: null,
    };
  },
  methods: {
    goBack() {
      parent.history.back();
    },
  },
  created() {
    this.currentRouteMeta = this.$route;
  },
};
</script>
