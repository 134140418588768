import Vue from "vue";
import App from "./App.vue";
import i18n from "@/libs/i18n";
import router from "./router";
import store from "./store";
import globalMixin from "@/utils/globalMixin";
import VueMq from "vue-mq";
import VueStarRating from "vue-star-rating";
import infiniteScroll from 'vue-infinite-scroll'
import VueScrollPicker from "vue-scroll-picker";
import VueLazyload from 'vue-lazyload';

import VCalendar from 'v-calendar';
// import DatePicker from 'v-calendar/lib/components/date-picker.umd'


import "vue-scroll-picker/dist/style.css"
import "wameed-ui/dist/index.css";
import "./assets/svg/index";
import "@/assets/scss/index.scss";
import "@/libs/axios";
import "@/libs/vue-cookies";
import "@/libs/sweet-alerts";
import "@/libs/google-map";
import '@/libs/firebase';

const loadimage = require('@/assets/images/default-image.png')
const errorimage = require('@/assets/images/default-image.png')
Vue.component('star-rating', VueStarRating)
Vue.config.productionTip = false;
Vue.mixin(globalMixin);
Vue.use(infiniteScroll);
Vue.use(VueScrollPicker); // export default is plugin
Vue.use(VCalendar); // export default is plugin
// Vue.component('calendar', VCalendar)
// Vue.component('date-picker', DatePicker)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1
});
Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    mobile: 450,
    tablet: 900,
    tabletPro: 1024,
    laptop: 1250,
    desktop: Infinity,
  },
});
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
