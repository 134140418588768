import Vue from "vue";
import VueRouter from "vue-router";
import pages from "./routes/pages";
import moment from "moment";
import i18n from "@/libs/i18n";
import store from "@/store";
import axios from "axios";
// Routes

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        offset: { x: 0, y: 200 },
        behavior: "smooth",
      };
    }
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    ...pages,
    { path: "/", redirect: `/${i18n.locale}/` },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  const langs = ["ar", "en"];
  axios.defaults.headers.common.locale = to.params.lang;
  store.commit("TOGGLE_RTL", to.params.lang);
  const loginRoutes = [
    "login",
    "login-required",
    "phone-verification",
    "complete-data",
  ];
  const loginData = Vue.$cookies.get("customer-talka-login-data", {
    parseJSON: true,
  });
  if (to.meta.mobileRoute) {
    var windowWidth = window.innerWidth;
    if (windowWidth > 1412) {
      return next({ name: "home" });
    }
  }
  if (langs.includes(to.params.lang)) {
    moment.locale(to.params.lang);
    if (loginRoutes.includes(to.name)) {
      if (loginData) {
        return next({
          name: "home",
        });
      }
      return next();
    }

    if (to.meta.middleware) {
      const middleware = Array.isArray(to.meta.middleware)
        ? to.meta.middleware
        : [to.meta.middleware];

      const context = {
        from,
        next,
        router,
        to,
      };
      const nextMiddleware = nextFactory(context, middleware, 1);

      return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
  } else {
    return next({ name: to.name, params: { lang: "ar" } });
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
