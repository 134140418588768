// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";
import {
  getAuth,
  signInWithCustomToken
} from "firebase/auth";

export default {
  async getOtp({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("auth/otp", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          return true;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async login({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("auth/login", data)
      .then((respo) => {
        if (respo.status === 200) {
          const date = new Date();
          const responseData = {
            ...respo.data.data.token,
            exp_date: date.setSeconds(
              date.getSeconds() + respo.data.data.token.expires_in
            ),
          };
          this._vm.$http.defaults.headers.common.Authorization = `Bearer ${responseData.access_token}`;
          this._vm.$cookies.set(
            "customer-talka-login-data",
            JSON.stringify(responseData),
            null,
            null,
            // "localhost",
            "devweb.talka-express.com",
            true
            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get("customer-talka-login-data", {
            parseJSON: true,
          });
          const auth = getAuth();
          signInWithCustomToken(auth, respo.data.data.firebase_token)
            .catch((error) => {
              console.log(error)
            });
          // window.location.reload();
          commit("loadingFinish");
          commit("setCodeValid", true);
          return respo.data;
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        commit("setCodeInvalid", true);
        throw error;
      });
  },
  async completeData({
    commit
  }, data) {
    commit("loadingStart", null);
    await this._vm.$http
      .post("auth/complete-data", data)
      .then((respo) => {
        if (respo.status === 200) {
          commit("loadingFinish");
          commit("successMessage", "data_saved_successfully");
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async logout({
    commit
  }, data) {
    commit("loadingStart", null);
    return await this._vm.$http
      .post("logout", data)
      .then((respo) => {
        if (respo.status === 200) {
          this._vm.$cookies.remove("customer-talka-login-data");
          this._vm.$cookies.remove(
            "customer-talka-login-data",
            null,
            // "localhost",
            "devweb.talka-express.com",
            true
            // httpOnly:true,
            // sameSite: true
          );

          const loginData = this._vm.$cookies.get("customer-talka-login-data", {
            parseJSON: true,
          });
          if (!loginData) {
            this._vm.$http.defaults.headers.common.Authorization = undefined;
            // commit("setLoggedOutuser");
            router.push({
              name: "home",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        }
      })
      .catch((error) => {
        commit("loadingFinish");
        throw error;
      });
  },
  async firebaseLogin({
    commit
  }, ) {
    await this._vm.$http
      .post('auth/firebase-login')
      .then((response) => {
        if (response.status === 200) {
          const auth = getAuth();
          signInWithCustomToken(auth, response.data.data)
            .catch((error) => {
              console.log(error)
            });

        }
      })
      .catch((error) => {

        commit('failMessage', 'firebase_fail', {
          root: true
        });
        throw error;
      });
  },
};