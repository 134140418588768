// import axios from '@/plugins/axios';
import i18n from "@/libs/i18n";
import router from "@/router";

export default {
  async loadFavoriteVendors({
    commit
  }, data) {
    await this._vm.$http
      .get("customer/v2/favorites/vendors", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setFavoriteVendors", response.data.data.content);
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  async addVendorToFavorite({
    commit
  }, data) {
    await this._vm.$http
      .post(`customer/v2/vendors/${data.vendor_type_id}/detail/${data.vendor_id}/add-to-favorite`, data)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
  async removeVendorFromFavorite({
    commit
  }, data) {
    await this._vm.$http
      .post(`customer/v2/vendors/${data.vendor_type_id}/detail/${data.vendor_id}/remove-from-favorite`, data)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
  async removeAllVendorsFromFavorite({
    commit
  }) {
    await this._vm.$http
      .post(`customer/v2/favorites/vendors/clear`)
      .then((response) => {
        commit("setFavoriteVendors", []);
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
  async loadFavoriteProducts({
    commit
  }, data) {
    await this._vm.$http
      .get("customer/v2/favorites/products", data)
      .then((response) => {
        if (response.status === 200) {
          commit("setFavoriteProducts", response.data.data.content);
        }
      })
      .catch((error) => {
        throw error;
      });
  },
  async addProductToFavorite({
    commit
  }, data) {
    await this._vm.$http
      .post(`customer/v2/vendors/${data.vendor_type_id}/detail/${data.vendor_id}/products/add-to-favorite/${data.product_id}`, data)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
  async removeProductFromFavorite({
    commit
  }, data) {
    await this._vm.$http
      .post(`customer/v2/vendors/${data.vendor_type_id}/detail/${data.vendor_id}/products/remove-from-favorite/${data.product_id}`, data)
      .then((response) => {
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
  async removeAllProductsFromFavorite({
    commit
  }) {
    await this._vm.$http
      .post(`customer/v2/favorites/products/clear`)
      .then((response) => {
        commit("setFavoriteProducts", []);
        return true;
      })
      .catch((error) => {
        throw error;
      });
  },
};