export default {
  setVendorCategory: (state, data) => {
    state.vendorCategory = data;
  },
  setVendors: (state, data) => {
    state.vendors = data;
  },
  setVendorDetailData: (state, data) => {
    state.vendorDetailData = data;
  },
  setVendorDetailProducts: (state, data) => {
    state.vendorDetailProducts = data;
  },
  setProductDetails: (state, data) => {
    state.productDetails = data;
  },
  setVendorDetailInfo: (state, data) => {
    state.vendorDetailInfo = data;
  },
  setVendorDetailReviews: (state, data) => {
    state.vendorDetailReviews = data;
  },
};
