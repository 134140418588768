import Vue from "vue";
import appIcon from "./app-icon.svg";
import fireIcon from "./fire.svg";
import locationIcon from "./location.svg";
import hourIcon from "./hour.svg";
import locationFooterIcon from "./location-footer.svg";
import phoneFooterIcon from "./phone-footer.svg";
import emailFooterIcon from "./email-footer.svg";
import wameedLogoIcon from "./wameed-logo.svg";
import searchIcon from "./search.svg";
import noNotificationsIcon from "./no-notifications.svg";
import noCartIcon from "./no-cart.svg";
import cartIcon from "./cart.svg";
import bellIcon from "./bell.svg";
import vectorLoginIcon from "./vector-login.vue";
import vectorVerificationCodeIcon from "./vector-verification-code.svg";
import closeIcon from "./close.svg";
import backModalIcon from "./back-modal.svg";
import yemenFlagIcon from "./yemen-flag.svg";
import usaFlagIcon from "./usa-flag.svg";
import imageDefaultIcon from "./image-default.svg";
import userImageDefaultIcon from "./user-image-default.svg";
import shareIcon from "./share.svg";
import heartIcon from "./heart.svg";
import walletIcon from "./wallet.svg";
import telIcon from "./tel.svg";
import telContactIcon from "./tel-contact.svg";
import deleteSearchHistoryIcon from "./delete-search-history.svg";
import increaseIcon from "./increase.svg";
import decreaseIcon from "./decrease.svg";
import loadingLogo from "./loading-logo.svg";
import errorAlertIcon from "./error-alert.svg";
import addMoreIcon from "./add_more.svg";
import notesIcon from "./notes.svg";
import trashIcon from "./trash.svg";
import arrowLeftIcon from "./arrow-left.svg";
import arrowDownIcon from "./arrow-down.svg";
import carIcon from "./car.svg";
import houseIcon from "./house.svg";
import officeIcon from "./office.svg";
import otherIcon from "./other.svg";
import paiementWhenRecievingIcon from "./paiement_when_recieving.svg";
import cashPayIcon from "./cash_pay.svg";
import floosakIcon from "./floosak.vue";
import jawaliIcon from "./jawali.svg";
import sabaCashIcon from "./saba_cash.svg";
import paymentAsKuraimiIcon from "./payment_as_a_transfer_-_Al-Kuraimi.svg";
import paymentAsNajmIcon from "./payment_as_a_transfer_-_Najm.svg";
import activeWizadIcon from "./active-wizad-icon.svg";
import noteIcon from "./note.svg";
import orderStatusIcon from "./order-status.svg";
import orderRateIcon from "./order-rate.svg";
import editDataIcon from "./edit-data.svg";
import addressesIcon from "./addresses.svg";
import settingsIcon from "./settings.svg";
import filterIcon from "./filter.svg";
import cancelIcon from "./cancel.svg";
import checkIcon from "./check.svg";
import alertErrorIcon from "./alert-error.svg";
import alertWarningIcon from "./alert-warning.svg";
import driverIcon from "./driver.svg";
import veryBadIcon from "./very_bad.svg";
import badIcon from "./bad.svg";
import notBadIcon from "./not_bad.svg";
import goodIcon from "./good.svg";
import excellentIcon from "./excellent.svg";
import logoDisabledIcon from "./logo-disabled.svg";
import uploadImageIcon from "./upload-image.svg";
import shopIcon from "./shop.svg";
import otherAddressIcon from "./other-address.svg";
import officeAddressIcon from "./office-address.svg";
import signOutIcon from "./sign_out.svg";
import facebookIcon from "./facebook.svg";
import twitterIcon from "./twitter.svg";
import instagramIcon from "./instagram.svg";
import youtubeIcon from "./youtube.svg";
import linkedinIcon from "./linkedin.svg";
import tikTokIcon from "./tik-tok.svg";
import appStoreIcon from "./app-store.svg";
import googlePlayIcon from "./google-play.svg";
import datePickerIcon from "./date-picker.svg";
import boxIcon from "./box.svg";
import galleryIcon from "./gallery.svg";
import addImageIcon from "./add-image.svg";
import deliverySwitchIcon from "./delivery-switch.svg";
import squareIcon from "./3square.svg";
import maximizeIcon from "./maximize.svg";
import notificationBingIcon from "./notification-bing.svg";
import mobileHomeIcon from "./mobile-home.svg";
import mobileOrdersIcon from "./mobile-orders.svg";
import mobileCartIcon from "./mobile-cart.svg";
import mobileFavoriteIcon from "./mobile-favorite.svg";
import mobileAccountIcon from "./mobile-account.svg";
import mobileSearchIcon from "./mobile-search.svg";
import whatsAppIcon from "./whatsApp.svg";
import technicalSupportEmailIcon from "./technical-support-email.svg";
import technicalSupportYoutubeIcon from "./technical-support-youtube.svg";
import technicalSupportInstagramIcon from "./technical-support-instagram.svg";
import technicalSupportFacebookIcon from "./technical-support-facebook.svg";
import technicalSupportTwitterIcon from "./technical-support-twitter.svg";
import locPositionIcon from "./loc-position.svg";
import technicalSupportBtnIcon from "./technical-support-btn.svg";
import couponIcon from "./coupon.svg";
import rotateRightIcon from "./rotate-right.svg";
import developedArIcon from "./developed-by-ar.svg";
import developedEnIcon from "./developed-by-en.svg";
import deliveryTypeIcon from "./delivery_type.svg";
import calenderIcon from "./calender.svg";
import chatIcon from "./chat.svg";
import trueIcon from "./true-icon.svg";
import errorIcon from "./error-icon.svg";
import chatSendIcon from "./chat-send-icon.svg";
import oPlusIcon from './o-plus-icon.svg';



Vue.component("active-wizad-icon", activeWizadIcon);
Vue.component("app-icon", appIcon);
Vue.component("fire-icon", fireIcon);
Vue.component("location-icon", locationIcon);
Vue.component("hour-icon", hourIcon);
Vue.component("location-footer-icon", locationFooterIcon);
Vue.component("phone-footer-icon", phoneFooterIcon);
Vue.component("email-footer-icon", emailFooterIcon);
Vue.component("wameed-logo-icon", wameedLogoIcon);
Vue.component("search-icon", searchIcon);
Vue.component("no-notifications-icon", noNotificationsIcon);
Vue.component("no-cart-icon", noCartIcon);
Vue.component("cart-icon", cartIcon);
Vue.component("bell-icon", bellIcon);
Vue.component("vector-login-icon", vectorLoginIcon);
Vue.component("vector-verification-code-icon", vectorVerificationCodeIcon);
Vue.component("close-icon", closeIcon);
Vue.component("back-modal-icon", backModalIcon);
Vue.component("yemen-flag-icon", yemenFlagIcon);
Vue.component("usa-flag-icon", usaFlagIcon);
Vue.component("image-default-icon", imageDefaultIcon);
Vue.component("user-image-default-icon", userImageDefaultIcon);
Vue.component("share-icon", shareIcon);
Vue.component("heart-icon", heartIcon);
Vue.component("wallet-icon", walletIcon);
Vue.component("tel-icon", telIcon);
Vue.component("tel-contact-icon", telContactIcon);
Vue.component("delete-search-history-icon", deleteSearchHistoryIcon);
Vue.component("increase-icon", increaseIcon);
Vue.component("decrease-icon", decreaseIcon);
Vue.component("loading-logo", loadingLogo);
Vue.component("error-alert-icon", errorAlertIcon);
Vue.component("add-more-icon", addMoreIcon);
Vue.component("notes-icon", notesIcon);
Vue.component("trash-icon", trashIcon);
Vue.component("arrow-left-icon", arrowLeftIcon);
Vue.component("arrow-down-icon", arrowDownIcon);
Vue.component("car-icon", carIcon);
Vue.component("house-icon", houseIcon);
Vue.component("office-icon", officeIcon);
Vue.component("other-icon", otherIcon);
Vue.component("paiement-when-recieving-icon", paiementWhenRecievingIcon);
Vue.component("cash-pay-icon", cashPayIcon);
Vue.component("floosak-icon", floosakIcon);
Vue.component("jawali-icon", jawaliIcon);
Vue.component("saba-cash-icon", sabaCashIcon);
Vue.component("payment-as-kuraimi-icon", paymentAsKuraimiIcon);
Vue.component("payment-as-najm-icon", paymentAsNajmIcon);
Vue.component("note-icon", noteIcon);
Vue.component("order-status-icon", orderStatusIcon);
Vue.component("order-rate-icon", orderRateIcon);
Vue.component("edit-data-icon", editDataIcon);
Vue.component("addresses-icon", addressesIcon);
Vue.component("settings-icon", settingsIcon);
Vue.component("filter-icon", filterIcon);
Vue.component("cancel-icon", cancelIcon);
Vue.component("check-icon", checkIcon);
Vue.component("alert-error-icon", alertErrorIcon);
Vue.component("alert-warning-icon", alertWarningIcon);
Vue.component("driver-icon", driverIcon);
Vue.component("very-bad-icon", veryBadIcon);
Vue.component("bad-icon", badIcon);
Vue.component("not-bad-icon", notBadIcon);
Vue.component("good-icon", goodIcon);
Vue.component("excellent-icon", excellentIcon);
Vue.component("logo-disabled-icon", logoDisabledIcon);
Vue.component("upload-image-icon", uploadImageIcon);
Vue.component("shop-icon", shopIcon);
Vue.component("other-address-icon", otherAddressIcon);
Vue.component("office-address-icon", officeAddressIcon);
Vue.component("sign-out-icon", signOutIcon);
Vue.component("facebook-icon", facebookIcon);
Vue.component("twitter-icon", twitterIcon);
Vue.component("instagram-icon", instagramIcon);
Vue.component("youtube-icon", youtubeIcon);
Vue.component("linkedin-icon", linkedinIcon);
Vue.component("tik-tok-icon", tikTokIcon);
Vue.component("app-store-icon", appStoreIcon);
Vue.component("google-play-icon", googlePlayIcon);
Vue.component("date-picker-icon", datePickerIcon);
Vue.component("box-icon", boxIcon);
Vue.component("gallery-icon", galleryIcon);
Vue.component("add-image-icon", addImageIcon);
Vue.component("delivery-switch-icon", deliverySwitchIcon);
Vue.component("square-icon", squareIcon);
Vue.component("maximize-icon", maximizeIcon);
Vue.component("notification-bing-icon", notificationBingIcon);
Vue.component("mobile-home-icon", mobileHomeIcon);
Vue.component("mobile-orders-icon", mobileOrdersIcon);
Vue.component("mobile-cart-icon", mobileCartIcon);
Vue.component("mobile-favorite-icon", mobileFavoriteIcon);
Vue.component("mobile-account-icon", mobileAccountIcon);
Vue.component("mobile-search-icon", mobileSearchIcon);
Vue.component("whatsApp-icon", whatsAppIcon);
Vue.component("technical-support-email-icon", technicalSupportEmailIcon);
Vue.component("technical-support-youtube-icon", technicalSupportYoutubeIcon);
Vue.component("technical-support-instagram-icon", technicalSupportInstagramIcon);
Vue.component("technical-support-facebook-icon", technicalSupportFacebookIcon);
Vue.component("technical-support-twitter-icon", technicalSupportTwitterIcon);
Vue.component("loc-position-icon", locPositionIcon);
Vue.component("technical-support-btn-icon", technicalSupportBtnIcon);
Vue.component("coupon-icon", couponIcon);
Vue.component("rotate-right-icon", rotateRightIcon);
Vue.component("developed-by-ar-icon", developedArIcon);
Vue.component("developed-by-en-icon", developedEnIcon);
Vue.component("delivery-type-icon", deliveryTypeIcon);
Vue.component("calender-icon", calenderIcon);
Vue.component("chat-icon", chatIcon);
Vue.component("true-icon", trueIcon);
Vue.component("error-icon", errorIcon);
Vue.component("chat-send-icon", chatSendIcon);
Vue.component('o-plus-icon', oPlusIcon);