<template>
  <div id="app">
    <FullLayout />
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FullLayout from "./layouts/FullLayout.vue";
import Loading from "@/components/Loading.vue";
export default {
  name: "App",
  components: {
    FullLayout,
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getUserInfo",
    }),
  },
  methods: {
    ...mapActions({
      loadUserInfo: "loadUserInfo",
      loadUserAddresses: "loadUserAddresses",
      loadCustomerDeliveryTo: "loadCustomerDeliveryTo",
      loadPaymentMethods: "loadPaymentMethods",
      loadVehicleTypes: "loadVehicleTypes",
      loadInfo: "loadInfo",
      loadHomeData: "loadHomeData",
      loadShippingBoundaries: "loadShippingBoundaries",
    }),
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
      const loginData = this.$cookies.get("customer-talka-login-data", {
        parseJSON: true,
      });
      if (loginData) {
        this.loadUserInfo().then(() => {
          this.$store.dispatch(
            "chat/loadUnreadMessagesCount",
            this.currentUser.id
          );
        });
        this.loadUserAddresses();
      }
      this.loadHomeData();
      this.loadInfo();
      this.loadCustomerDeliveryTo();
      this.loadPaymentMethods();
      this.loadVehicleTypes();
      this.loadShippingBoundaries();
    }, 2000);
  },
};
</script>
