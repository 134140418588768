<template>
  <b-modal
    id="wameed-modal"
    v-model="show"
    class="wameed-modal"
    title=""
    no-fade
    hide-header
    hide-footer
    :dialog-class="dialogClass ? dialogClass + ' fixed-modal-width ' : ' fixed-modal-width '"
    :no-close-on-backdrop="$mq != 'mobile' && $mq != 'tablet' ? true : false"
  >
    <div class="align-items-center w-100">
      <div
        class="d-flex flex-column justify-content-between align-items-center w-100 mx-auto"
      >
        <b-avatar :variant="'light-' + variant2" size="70">
          <component :is="iconName" />
        </b-avatar>

        <h4 class="wameed-modal-title">
          {{ title }}
        </h4>
        <h4 class="wameed-modal-subtitle">
          {{ subTitle }}
        </h4>
      </div>
      <div class="d-flex justify-content-between align-items-center w-100">
        <WameedBtn
          :variant="variant"
          pill
          :title="btnTitle ? btnTitle : $t('common.ok')"
          classes="wameed-modal-btn text-white w-100"
          @onClick="submitAction()"
        />
        <WameedBtn
          :variant="btn2Variant ? btn2Variant : 'disable'"
          pill
          :title="btn2Title ? btn2Title : $t('common.cancel')"
          :classes="
            btn2Title
              ? 'wameed-modal-btn  text-white w-100 mr-3'
              : 'wameed-modal-btn text-font-secondary w-100 mr-3'
          "
          @onClick="submit2Action() ? submit2Action() : (show = false)"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BAvatar } from "bootstrap-vue";
import { WameedBtn } from "wameed-ui/dist/wameed-ui.esm";
export default {
  components: { WameedBtn, BModal, BAvatar },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: null,
    },
    variant2: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subTitle: {
      type: String,
      default: null,
    },
    btnTitle: {
      type: String,
      default: null,
    },
    btn2Title: {
      type: String,
      default: null,
    },
    btn2Variant: {
      type: String,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
    dialogClass: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    submitAction() {
      this.$emit("submitAction");
    },
    submit2Action() {
      this.$emit("submit2Action");
    },
  },
};
</script>
