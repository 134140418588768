import Vue from "vue";
import store from "@/store/index";
import axios from "axios";
import i18n from "@/libs/i18n";
import router from "@/router";

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = `${process.env.VUE_APP_AXIOS_URL}/`;
Vue.prototype.$http.defaults.headers.common.locale = i18n.locale;
Vue.prototype.$http.defaults.headers.post["Content-Type"] =
  "application/json;  charset=UTF-8";

Vue.prototype.$http.interceptors.request.use((request) => {
  // Update token axios header
  const authToken = request.headers.Authorization;
  if (!authToken) {
    const loginData = Vue.$cookies.get("customer-talka-login-data", {
      parseJSON: true,
    });
    if (loginData) {
      request.headers.Authorization = `Bearer ${loginData.access_token}`;
    }
  }
  return request;
});

Vue.prototype.$http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 400) {
      if (error.response.data.code == 400) {
        store.commit(
          "failMessage",
          error.response.data.code ? error.response.data.data : "error"
        );
      } else {
        store.commit(
          "failMessage",
          error.response.data.code ? error.response.data.code : "error"
        );
      }
    }

    if (error.response.status === 401) {
      // store.dispatch("logout");
      store.commit("failMessage", "login_message");
      // store.commit("failMessage", error.response.status);
    }
    if (error.response.status === 422 || error.response.status === 500) {
      store.commit("failMessage", error.response.status);
    }
    if (error.response.status === 404) {
      // router.push({
      //   name: "page-not-found",
      //   params: {
      //     lang: i18n.locale,
      //   },
      // });
    }
    if (error.response.status === 403) {
      router.push({
        name: "page-not-authorized",
        params: {
          lang: i18n.locale,
        },
      });
    }
  }
);
