export default {
  getVendorCategory: (state) => {
    return state.vendorCategory;
  },
  getVendors: (state) => {
    return state.vendors;
  },
  getVendorDetailData: (state) => {
    return state.vendorDetailData;
  },
  getVendorDetailProducts: (state) => {
    return state.vendorDetailProducts;
  },
  getProductDetails: (state) => {
    return state.productDetails;
  },
  getVendorDetailInfo: (state) => {
    return state.vendorDetailInfo;
  },
  getVendorDetailReviews: (state) => {
    return state.vendorDetailReviews;
  },
};
