import i18n from "@/libs/i18n";

const userTypes = {
    admin: 1,
    customer: 2,
    vendor: 3,
    driver: 4,
}

const accountType = [
    {
        id: 1,
        name: i18n.t('account_type.personal'),
    },
    {
        id: 2,
        name: i18n.t('account_type.company'),
    },

];

function filterAccountType(id) {
    return accountType.find((item) => item.id === id);
}

export {userTypes, accountType, filterAccountType};
