export default {
  getIsLoggedIn(state) {
    return state.isLoggedIn;
  },
  getUserInfo(state) {
    return state.userInfo;
  },
  getNotifications(state) {
    return state.notifications;
  },
  getAddressesTypes(state) {
    return state.addressesTypes;
  },
  getUserAddresses(state) {
    return state.userAddresses;
  },

  getUserOrders(state) {
    return state.userOrders;
  },
  getUserOrderDetail(state) {
    return state.userOrderDetail;
  },
  getUserOrdersFilters(state) {
    return state.userOrdersFilters;
  },
};
