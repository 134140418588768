import { wameedNotify } from "wameed-ui/dist/wameed-ui.esm";
import Vue from "vue";
import i18n from "@/libs/i18n";

export default {
  TOGGLE_RTL(state, payload) {
    state.isRTL = payload == "en" ? false : true;
    state.lang = payload;
    i18n.locale = payload;
    document.querySelector("html").setAttribute("lang", payload);
    document.documentElement.setAttribute("dir", state.isRTL ? "rtl" : "ltr");
  },
  successMessage: (state, data) => {
    var windowWidth = window.innerWidth;
    var position = "bottom-left";
    if (windowWidth < 1040) {
      position = "top-center";
    }
    wameedNotify({
      title: i18n.t("success_messages." + data),
      type: "success",
      position: position,
    });
    state.messages = "success";
  },
  failMessage: (state, data) => {
    var windowWidth = window.innerWidth;
    var position = "bottom-left";
    if (windowWidth < 1040) {
      position = "top-center";
    }
    wameedNotify({
      title: i18n.t("error_messages." + data),
      type: "fail",
      position: position,
    });
    state.messages = "error";
  },
  loadingFinish: (state) => {
    state.loading = false;
  },
  loadingStart: (state) => {
    state.loading = true;
  },
  loadAddToCartTrue: (state) => {
    state.addedToCart = true;
  },
  loadAddToCartFalse: (state) => {
    state.addedToCart = false;
  },
};
