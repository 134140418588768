export default {
  getInfo(state) {
    return state.info;
  },
  getSearch(state) {
    return state.search;
  },
  getSearchProducts(state) {
    return state.searchProducts;
  },
  getPrivacy(state) {
    return state.privacy;
  },
  getTerms(state) {
    return state.terms;
  },
  getCustomerDeliveryTo(state) {
    return state.customerDeliveryTo;
  },
  getPaymentMethods(state) {
    return state.paymentMethods;
  },
  getVehicleTypes(state) {
    return state.vehicleTypes;
  },
  getOrderTypes(state) {
    return state.orderTypes;
  },
  getShippingBoundaries(state) {
    return state.shippingBoundaries;
  },
  getDiscounts(state) {
    return state.discounts;
  },
  getCoupon(state) {
    return state.coupon;
  },
  getCountries: (state) => {
    return state.countries;
  },
  getCities: (state) => {
    return state.cities;
  },
};