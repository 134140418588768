export default {
    isLoggedIn: false,
    userInfo: null,
    notifications: null,
    addressesTypes: null,
    userAddresses: null,
    userOrders: null,
    userOrderDetail: null,
    userOrdersFilters: null,
};
