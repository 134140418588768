
export default {
  async loadHomeData({ commit }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get("web")
      .then((response) => {
        if (response.status === 200) {
          commit("setHomeData", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
  async loadSuggestKeywords({ commit }, data) {
    // commit("loadingStart", null);
    return await this._vm.$http
      .get("web/suggest-keywords")
      .then((response) => {
        if (response.status === 200) {
          commit("setSuggestKeywords", response.data.data);
          // commit("loadingFinish");
        }
      })
      .catch((error) => {
        // commit("loadingFinish");
        throw error;
      });
  },
};
