export default {
  setIsLoggedIn: (state, data) => {
    state.isLoggedIn = data;
  },
  setUserInfo: (state, data) => {
    state.userInfo = data;
  },
  setNotifications: (state, data) => {
    state.notifications = data;
  },
  setAddressesTypes: (state, data) => {
    state.addressesTypes = data;
  },
  setUserAddresses: (state, data) => {
    state.userAddresses = data;
  },

  setUserOrders: (state, data) => {
    state.userOrders = data;
  },
  setUserOrderDetail: (state, data) => {
    state.userOrderDetail = data;
  },
  setUserOrdersFilters: (state, data) => {
    state.userOrdersFilters = data;
  },
};
