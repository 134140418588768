<template>
  <div>
    <wameed-form class="w-100" @onSubmit="submitAction">
      <template v-slot="{ invalid }">
        <WameedTextInput
          :name="$t('from.phone')"
          :label="$t('from.phone')"
          v-model="phone"
          :fieldClasses="
            onFocus ? 'border-input-group-primary phone-input' : 'phone-input'
          "
          placeholder="7-- --- ---"
          :isAppend="true"
          maxLength="9"
          rules="max:9|numeric|regex:^([7]\d{8})$"
          @input="changeValue"
          @onFocus="onFocus = true"
        >
          <!-- type="number" -->

          <!-- /^([7][7|3|1|0|8]\d{7})$/ -->
          <template :slot="'append-slot'">
            <yemen-flag-icon class="mr-1" /> +967
          </template>
        </WameedTextInput>
        <wameed-btn
          variant="secondary"
          :title="$t('common.sign_in')"
          classes="text-reg-14 px-4 text-white w-100 auth-submit-btn"
          pill
          :disabled="invalid || phone == ''"
          type="submit"
        />
      </template>
    </wameed-form>
    <wameed-btn
      v-if="continueWithoutSignInShow"
      variant="white"
      :title="$t('common.continue_without_sign_in')"
      classes="text-reg-14 px-4 text-gray-3 w-100 mt-4"
      pill
      @onClick="continueWithoutSignIn()"
    />
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";
import {
  WameedForm,
  WameedBtn,
  WameedTextInput,
} from "wameed-ui/dist/wameed-ui.esm";

export default {
  components: {
    WameedForm,
    WameedBtn,
    WameedTextInput,
    BFormInput,
  },
  props: {
    continueWithoutSignInShow: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      phone: "",
      onFocus: false,
    };
  },
  methods: {
    changeValue() {
      this.$emit("setPhoneValue", this.phone);
    },
    submitAction() {
      this.$emit("submitAction");
    },
    continueWithoutSignIn() {
      this.$emit("continueWithoutSignIn");
    },
  },
};
</script>
