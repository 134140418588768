export default {
  setInfo: (state, data) => {
    state.info = data;
  },
  setSearch: (state, data) => {
    state.search = data;
  },
  setSearchProducts: (state, data) => {
    state.searchProducts = data;
  },
  setPrivacy: (state, data) => {
    state.privacy = data;
  },
  setTerms: (state, data) => {
    state.terms = data;
  },
  setCustomerDeliveryTo: (state, data) => {
    state.customerDeliveryTo = data;
  },
  setPaymentMethods: (state, data) => {
    state.paymentMethods = data;
  },
  setVehicleTypes: (state, data) => {
    state.vehicleTypes = data;
  },
  setOrderTypes: (state, data) => {
    state.orderTypes = data;
  },
  setShippingBoundaries: (state, data) => {
    state.shippingBoundaries = data;
  },
  setDiscounts: (state, data) => {
    state.discounts = data;
  },
  setCoupon: (state, data) => {
    state.coupon = data;
  },

  setCountries: (state, data) => {
    state.countries = data;
  },
  setCities: (state, data) => {
    state.cities = data;
  },
};